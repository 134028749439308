import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createStockPart, getStock, updateStock } from "./store/actions";
import { Stock } from "./Stock";
import { getSuppliersDirectory } from "../../../redux/actions/directories";

export function StockContainer() {
  // Pagination
  const [, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  useEffect(() => {
    onGetSuppliers();
  }, []);

  // Queries
  const stock = useSelector((state) => state.stock.stock);
  const suppliers = useSelector(
    (state) => state.directories.suppliers.suppliers
  );

  // Dispatches
  const dispatch = useDispatch();
  const onGetSuppliers = () => dispatch(getSuppliersDirectory());
  const onGetStock = (payload) => dispatch(getStock(payload));
  const onUpdateStock = (payload) => dispatch(updateStock(payload));
  const onCreateStockPart = (payload) => dispatch(createStockPart(payload));

  const offsetChanged = (offset) => {
    setOffset(offset);
    onGetStock({ offset, take: pageSize });
  };

  const update = (part) => onUpdateStock(part);
  const create = (part) => onCreateStockPart(part);

  return (
    <Stock
      stock={stock}
      suppliers={suppliers}
      create={create}
      update={update}
      pageSize={pageSize}
      offsetChanged={offsetChanged}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
    />
  );
}
