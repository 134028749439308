import React, { useState } from "react";
import { Collapse, ul } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressBook,
  faAddressCard,
  faChartBar,
  faHandshake,
  faMailBulk,
  faUsers,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";

function FullSidebar(props) {
  const [collapseOpenName, setCollapseOpenName] = useState("");

  const sidebarObject = [
    { name: "Contacts", icon: faAddressBook, route: "/app/contacts" },
    { name: "Users", icon: faUsers, route: "/app/users" },
    { name: "Partners", icon: faHandshake, route: "/app/job/partners" },
    {
      name: "Warehouse",
      icon: faWarehouse,
      children: [
        { name: "Suppliers", route: "/app/warehouse/suppliers" },
        { name: "Parts", route: "/app/warehouse/parts" },
        { name: "Stock", route: "/app/warehouse/stock" },
      ],
    },
  ];

  // const sidebarObject = [
  //   { name: "Contacts", icon: faAddressBook, route: "/app/contacts" },
  //   { name: "Users", icon: faUsers, route: "/app/users" },    {
  //     name: "Job",
  //     icon: faHandshake,
  //     children: [
  //       { name: "Partners", route: "/app/job/partners" },
  //       { name: "Contracts", route: "/app/job/contracts" },
  //       { name: "Projects", route: "/app/job/projects" }
  //     ]
  //   },
  //   { name: "Mail", icon: faMailBulk, route: "/app/mail" },
  //   { name: "Reports", icon: faChartBar, route: "/app/reports" }
  // ];

  return (
    <Sidebar className="col-md-2 d-none d-md-block sidebar">
      <SidebarSticky className="sidebar-sticky">
        <ul className="nav flex-column pl-3">
          {sidebarObject.map((item, index) => {
            if (item.children) {
              return (
                <div key={index}>
                  <li className="nav-item">
                    <StyledLink
                      className="nav-link"
                      onClick={() => setCollapseOpenName(item.name)}
                      aria-controls="example-collapse-text"
                      aria-expanded={collapseOpenName === item.name}
                    >
                      {item.icon ? (
                        <FontAwesomeIconStyled
                          fixedWidth={true}
                          icon={item.icon}
                        />
                      ) : (
                        ""
                      )}
                      {item.name}
                    </StyledLink>
                  </li>
                  <Collapse in={collapseOpenName === item.name}>
                    <ul>
                      {item.children.map((child, index) => {
                        return (
                          <li className="nav-item" key={index}>
                            <StyledNavLink
                              className=" nav-link"
                              exact={true}
                              activeClassName="active"
                              to={child.route}
                            >
                              {child.icon ? (
                                <FontAwesomeIconStyled
                                  fixedWidth={true}
                                  icon={child.icon}
                                />
                              ) : (
                                ""
                              )}
                              {child.name}
                            </StyledNavLink>
                          </li>
                        );
                      })}
                    </ul>
                  </Collapse>
                </div>
              );
            } else {
              return (
                <li className="nav-item" key={index}>
                  <StyledNavLink
                    className=" nav-link"
                    exact={true}
                    activeClassName="active"
                    to={item.route}
                  >
                    {item.icon ? (
                      <FontAwesomeIconStyled
                        fixedWidth={true}
                        icon={item.icon}
                      />
                    ) : (
                      ""
                    )}
                    {item.name}
                  </StyledNavLink>
                </li>
              );
            }
          })}
        </ul>
      </SidebarSticky>
    </Sidebar>
  );
}

const Sidebar = styled.nav`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 55px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  background-color: #162636;
`;

const SidebarSticky = styled.div`
  font-size: 16px;
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
`;

const StyledNavLink = styled(NavLink)`
  font-weight: 500;
  color: #ffffff;
  transition: 0.3s;
  &:hover {
    color: #62c7ae;
  }
  &.active {
    color: #43aa8b;
  }
`;

const StyledLink = styled.span`
  font-weight: 500;
  cursor: pointer;
  color: #ffffff;
  transition: 0.3s;
  &:hover {
    color: #62c7ae;
  }
`;

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

export default FullSidebar;
