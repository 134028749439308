import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { Card } from "react-bootstrap";

function DashboardLineChart(props) {
  return (
    <>
      <Card style={{ height: "23rem" }}>
        <Card.Body style={{ padding: 0 }}>
          <ResponsiveLine
            data={data}
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            xScale={{ type: "point" }}
            yScale={{ type: "linear", stacked: true, min: "auto", max: "auto" }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "transportation",
              legendOffset: 36,
              legendPosition: "middle"
            }}
            axisLeft={{
              orient: "left",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "count",
              legendOffset: -40,
              legendPosition: "middle"
            }}
            colors={{ scheme: "nivo" }}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
              {
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ]}
          />
        </Card.Body>
      </Card>
    </>
  );
}

export default DashboardLineChart;

const data = [
  {
    id: "japan",
    color: "hsl(118, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 164
      },
      {
        x: "helicopter",
        y: 230
      },
      {
        x: "boat",
        y: 104
      },
      {
        x: "train",
        y: 21
      },
      {
        x: "subway",
        y: 179
      },
      {
        x: "bus",
        y: 93
      },
      {
        x: "car",
        y: 278
      },
      {
        x: "moto",
        y: 170
      },
      {
        x: "bicycle",
        y: 113
      },
      {
        x: "horse",
        y: 85
      },
      {
        x: "skateboard",
        y: 5
      },
      {
        x: "others",
        y: 280
      }
    ]
  },
  {
    id: "france",
    color: "hsl(276, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 212
      },
      {
        x: "helicopter",
        y: 220
      },
      {
        x: "boat",
        y: 132
      },
      {
        x: "train",
        y: 222
      },
      {
        x: "subway",
        y: 268
      },
      {
        x: "bus",
        y: 159
      },
      {
        x: "car",
        y: 57
      },
      {
        x: "moto",
        y: 299
      },
      {
        x: "bicycle",
        y: 7
      },
      {
        x: "horse",
        y: 233
      },
      {
        x: "skateboard",
        y: 259
      },
      {
        x: "others",
        y: 283
      }
    ]
  },
  {
    id: "us",
    color: "hsl(243, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 91
      },
      {
        x: "helicopter",
        y: 201
      },
      {
        x: "boat",
        y: 68
      },
      {
        x: "train",
        y: 115
      },
      {
        x: "subway",
        y: 162
      },
      {
        x: "bus",
        y: 79
      },
      {
        x: "car",
        y: 231
      },
      {
        x: "moto",
        y: 14
      },
      {
        x: "bicycle",
        y: 57
      },
      {
        x: "horse",
        y: 20
      },
      {
        x: "skateboard",
        y: 195
      },
      {
        x: "others",
        y: 37
      }
    ]
  },
  {
    id: "germany",
    color: "hsl(94, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 84
      },
      {
        x: "helicopter",
        y: 294
      },
      {
        x: "boat",
        y: 61
      },
      {
        x: "train",
        y: 16
      },
      {
        x: "subway",
        y: 285
      },
      {
        x: "bus",
        y: 191
      },
      {
        x: "car",
        y: 8
      },
      {
        x: "moto",
        y: 135
      },
      {
        x: "bicycle",
        y: 235
      },
      {
        x: "horse",
        y: 122
      },
      {
        x: "skateboard",
        y: 47
      },
      {
        x: "others",
        y: 277
      }
    ]
  },
  {
    id: "norway",
    color: "hsl(116, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 257
      },
      {
        x: "helicopter",
        y: 263
      },
      {
        x: "boat",
        y: 154
      },
      {
        x: "train",
        y: 208
      },
      {
        x: "subway",
        y: 177
      },
      {
        x: "bus",
        y: 55
      },
      {
        x: "car",
        y: 257
      },
      {
        x: "moto",
        y: 47
      },
      {
        x: "bicycle",
        y: 28
      },
      {
        x: "horse",
        y: 272
      },
      {
        x: "skateboard",
        y: 250
      },
      {
        x: "others",
        y: 260
      }
    ]
  }
];
