import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../../common/api/api.base";
import { toast } from "react-toastify";
import {
  GET_TEAMS_DIRECTORY,
  getTeamsDirectoryError,
  getTeamsDirectorySuccess,
} from "../../actions/directories/teamsDirectory.actions";

function* workerGetTeamsDirectory(action) {
  try {
    const result = yield call(
      ApiBase.get,
      `/api/directories/teams`,
      action.payload
    );
    if (!result.error) {
      yield put(getTeamsDirectorySuccess(result.value));
    } else {
      yield put(getTeamsDirectoryError(`Failed to get GetTeamsDirectory!`));
    }
  } catch (e) {
    yield put(getTeamsDirectoryError(e.message));
  }
}

export default function* watchTeamsDirectorySaga() {
  yield takeEvery(GET_TEAMS_DIRECTORY, workerGetTeamsDirectory);
}
