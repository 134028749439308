/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import Select from "react-select";
import PropTypes from "prop-types";


export const HookSelect = ({options, selectedOption = null, register, setValue, placeholder = '...', name, ...other}) => {
  const [selectValue, setSelectValue] = useState(selectedOption);

  useEffect(() => {
    register({name: name});
  }, []);

  useEffect(() => {
    if (selectedOption && options) {
      handleChange(options.find(option => option.value === selectedOption));
    } else {
      handleChange(null);
    }
  }, [selectedOption, options]);

  const handleChange = option => {
    setSelectValue(option);
    setValue(name, option && option.value);
  };

  return (
    <Select
      className="reactSelect"
      name={name}
      placeholder={placeholder}
      value={selectValue}
      options={options}
      onChange={handleChange}
      {...other}
    />
  );
};

HookSelect.propTypes = {
  selectedOption: PropTypes.number,
  options: PropTypes.instanceOf(Array).isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default HookSelect;
