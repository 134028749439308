import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../../common/api/api.base";
import { toast } from "react-toastify";
import {
  GET_SUPPLIERS_DIRECTORY,
  getSuppliersDirectoryError,
  getSuppliersDirectorySuccess,
} from "../../actions/directories/suppliersDirectory.actions";

function* workerGetSuppliersDirectory(action) {
  try {
    const result = yield call(
      ApiBase.get,
      `/api/directories/suppliers`,
      action.payload
    );
    if (!result.error) {
      yield put(getSuppliersDirectorySuccess(result.value));
    } else {
      yield put(getSuppliersDirectoryError(`Failed to get contacts!`));
    }
  } catch (e) {
    yield call(toast.error, e.message);
    yield put(getSuppliersDirectoryError(e.message));
  }
}

export function* watchSuppliersDirectorySaga() {
  yield takeEvery(GET_SUPPLIERS_DIRECTORY, workerGetSuppliersDirectory);
}
