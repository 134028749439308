import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../../common/api/api.base";
import { toast } from "react-toastify";
import {
  GET_DEPARTMENTS_DIRECTORY,
  getDepartmentsDirectoryError,
  getDepartmentsDirectorySuccess,
} from "../../actions/directories/departmentsDirectory.actions";

function* workerGetDepartmentsDirectory() {
  try {
    const result = yield call(ApiBase.get, `/api/directories/departments`);
    if (!result.error) {
      yield put(getDepartmentsDirectorySuccess(result.value));
    } else {
      yield put(
        getDepartmentsDirectoryError(`Failed to get GetDepartmentsDirectory!`)
      );
    }
  } catch (e) {
    yield put(getDepartmentsDirectoryError(e.message));
  }
}

export default function* watchDepartmentsDirectorySaga() {
  yield takeEvery(GET_DEPARTMENTS_DIRECTORY, workerGetDepartmentsDirectory);
}
