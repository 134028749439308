import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Auth from "./pages/Auth/Auth.container";
import FullLayout from "./pages/App.container";
import NotFound from "./pages/NotFound/NotFound";
import PrivateRoute from "./common/helpers/routing/PrivateRoute";

import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { getCurrentUser } from "./common/helpers/auth.helper";

function App() {
  return (
    <>
      <Switch>
        <Route path={"/auth"} component={Auth} />
        <PrivateRoute path={"/app"} component={FullLayout} />
        <Redirect to={"/app"} from={"/"} />
        <Route component={NotFound} />
      </Switch>
      <ToastContainer autoClose={2000} />
    </>
  );
}

export default App;
