import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../../common/api/api.base";
import {
  CREATE_CONTACT,
  createContactError,
  createContactSuccess,
  GET_CONTACT,
  GET_CONTACTS,
  getContact,
  getContactError,
  getContacts,
  getContactsError,
  getContactsSuccess,
  getContactSuccess,
  UPDATE_CONTACT,
  updateContactError,
  updateContactSuccess
} from "./actions";

function* workerGetContacts(action) {
  try {
    const result = yield call(ApiBase.get, `/api/contacts`, action.payload);
    if (!result.error) {
      yield put(getContactsSuccess(result.value));
    } else {
      yield put(getContactsError(`Failed to get contacts!`));
    }
  } catch (e) {
    yield put(getContactsError(e.message));
  }
}

function* workerGetContact(action) {
  try {
    const result = yield call(ApiBase.get, `/api/contacts/${action.payload}`);
    if (!result.error) {
      yield put(getContactSuccess(result.value));
    } else {
      yield put(getContactError(`Failed to get contacts!`));
    }
  } catch (e) {
    yield put(getContactError(e.message));
  }
}

function* workerUpdateContact(action) {
  try {
    const result = yield call(
      ApiBase.put,
      `/api/contacts`,
      action.payload.model
    );
    if (!result.error) {
      yield put(updateContactSuccess(result.value));
      // Call update contacts list
      yield put(getContacts(action.payload.request));
      yield put(getContact(action.payload.model.id));
    } else {
      yield put(updateContactError(`Failed to get contacts!`));
    }
  } catch (e) {
    yield put(updateContactError(e.message));
  }
}

function* workerCreateContact(action) {
  try {
    const result = yield call(
      ApiBase.post,
      `/api/contacts`,
      action.payload.model
    );
    if (!result.error) {
      yield put(createContactSuccess(result.value));
      // Call update contacts list
      yield put(getContacts(action.payload.request));
    } else {
      yield put(createContactError("Failed to create contact!"));
    }
  } catch (e) {
    yield put(createContactError(e.message));
  }
}

export function* watchContactsSaga() {
  yield takeEvery(GET_CONTACTS, workerGetContacts);
  yield takeEvery(GET_CONTACT, workerGetContact);
  yield takeEvery(UPDATE_CONTACT, workerUpdateContact);
  yield takeEvery(CREATE_CONTACT, workerCreateContact);
}
