import {
  CREATE_PART,
  CREATE_PART_ERROR,
  CREATE_PART_SUCCESS,
  GET_PARTS,
  GET_PARTS_ERROR,
  GET_PARTS_SUCCESS,
  UPDATE_PART,
  UPDATE_PART_ERROR,
  UPDATE_PART_SUCCESS,
} from "./actions";

const initialState = {
  error: null,
  parts: {
    total: 0,
    entities: [],
  },
  loading: false,
  loaded: false,
};

export function partsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PARTS:
      return { ...state, loading: true, loaded: false };
    case GET_PARTS_SUCCESS:
      return { ...state, parts: action.payload, loading: false, loaded: true };
    case GET_PARTS_ERROR:
      return { ...state, loading: false, loaded: false, error: action.error };
    case CREATE_PART:
      return { ...state, loading: true, loaded: false };
    case CREATE_PART_SUCCESS:
      return { ...state, loading: false, loaded: true };
    case CREATE_PART_ERROR:
      return { ...state, loading: false, loaded: false, error: action.error };
    case UPDATE_PART:
      return { ...state, loading: true, loaded: false };
    case UPDATE_PART_SUCCESS:
      return { ...state, loading: false, loaded: true };
    case UPDATE_PART_ERROR:
      return { ...state, loading: false, loaded: false, error: action.error };
    default:
      return state;
  }
}
