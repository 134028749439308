import React, { useEffect, useState } from "react";
import { Button, Col, Form, Image, Modal, Row } from "react-bootstrap";
import useForm from "react-hook-form";
import HookDatePicker from "../../../common/components/wrappers/HookDatepicker";
import PropTypes from "prop-types";
import CropperModal from "../../../common/components/CropperModal/CropperModal";
import SingleFileInput from "../../../common/components/FileInput/SingleFileInput";

function ContactEditModal({
  handleClose,
  handleUpdate,
  show,
  selectedContact,
}) {
  const { register, handleSubmit, errors, setValue, reset } = useForm({
    defaultValues: selectedContact,
  });

  useEffect(() => {
    // you can do async server request and fill up form
    reset({ ...selectedContact });
    if (selectedContact) {
      setFileForUpload(selectedContact.avatarData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContact]);

  const [showCropper, setShowCropper] = useState(false);
  const handleCloseCropper = () => setShowCropper(false);

  const [fileForPreview, setFileForPreview] = useState(null);
  const [fileForUpload, setFileForUpload] = useState(null);

  const handleSetFileForPreview = (file) => {
    setFileForPreview(URL.createObjectURL(file));
    setShowCropper(true);
  };

  const handleSubmitUpdate = (values) => {
    handleUpdate({
      ...values,
      id: selectedContact.id,
      avatarData: fileForUpload,
    });
    handleClose();
  };

  const handleImageCropped = (image) => {
    setFileForUpload(image);
  };

  const closeModal = () => {
    handleClose();
    setFileForPreview(null);
    setFileForUpload(null);
  };

  return (
    <>
      <Modal size={"lg"} show={show && !showCropper} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Update{" "}
            {selectedContact && (
              <>
                {selectedContact.firstName} {selectedContact.lastName}
              </>
            )}
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(handleSubmitUpdate)}>
          <Modal.Body>
            <Row>
              <Col md={6} lg={4}>
                <Form.Group>
                  <Form.Label htmlFor={"avatar"}>Avatar</Form.Label>
                  <SingleFileInput
                    showFileUpload={false}
                    fileChanged={(file) => handleSetFileForPreview(file)}
                  />
                </Form.Group>
                {fileForUpload ? (
                  <Image
                    className="fluid"
                    height={240}
                    width={240}
                    src={fileForUpload}
                  />
                ) : (
                  <Image
                    className="fluid"
                    height={240}
                    width={240}
                    src={selectedContact && selectedContact.avatarData}
                  />
                )}
              </Col>
              <Col>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label htmlFor={"firstName"}>First Name</Form.Label>
                      <Form.Control
                        type={"text"}
                        className={errors.firstName ? "is-invalid" : ""}
                        ref={register({ required: true })}
                        name={"firstName"}
                        placeholder={"..."}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label htmlFor={"lastName"}>Last Name</Form.Label>
                      <Form.Control
                        type={"text"}
                        className={errors.lastName ? "is-invalid" : ""}
                        ref={register({ required: true })}
                        name={"lastName"}
                        placeholder={"..."}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Birth Date</Form.Label>
                      <HookDatePicker
                        name={"birthDate"}
                        placeholder={"Select date..."}
                        register={register}
                        setValue={setValue}
                        selected={selectedContact && selectedContact.birthDate}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label htmlFor={"phone"}>Phone</Form.Label>
                      <Form.Control
                        type={"text"}
                        ref={register}
                        name={"phone"}
                        placeholder={"..."}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label htmlFor={"email"}>Email</Form.Label>
                      <Form.Control
                        type={"text"}
                        className={errors.email ? "is-invalid" : ""}
                        ref={register({ required: true })}
                        name={"email"}
                        placeholder={"..."}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label htmlFor={"address"}>Address</Form.Label>
                      <Form.Control
                        type={"text"}
                        ref={register}
                        name={"address"}
                        placeholder={"..."}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label htmlFor={"zipCode"}>Zip Code</Form.Label>
                      <Form.Control
                        type={"text"}
                        ref={register}
                        name={"zipCode"}
                        placeholder={"..."}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label htmlFor={"skype"}>Skype</Form.Label>
                      <Form.Control
                        type={"text"}
                        ref={register}
                        name={"skype"}
                        placeholder={"..."}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label htmlFor={"middleName"}>
                        Middle Name
                      </Form.Label>
                      <Form.Control
                        type={"text"}
                        ref={register}
                        name={"middleName"}
                        placeholder={"..."}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <Button variant="accent" type="submit">
              Update
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <CropperModal
        handleImageCropped={handleImageCropped}
        handleClose={handleCloseCropper}
        show={showCropper}
        previewImg={fileForPreview}
      />
    </>
  );
}

ContactEditModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  selectedContact: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    middleName: PropTypes.string,
    birthDate: PropTypes.instanceOf(Date) | PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    skype: PropTypes.string,
    address: PropTypes.string,
    zipCode: PropTypes.string,
    contactTypeId: PropTypes.number | null,
    genderId: PropTypes.number | null,
    cityId: PropTypes.number | null,
    jobId: PropTypes.number | null,
    avatarId: PropTypes.number | null,
    departmentId: PropTypes.number | null,
  }),
};

export default ContactEditModal;
