import { toast } from "react-toastify";
import axios from "axios";
import { getCurrentUser } from "../helpers/auth.helper";
import { dispatch } from "../../redux/root.store";
import { logoutUser } from "../../redux/actions/auth.actions";

export const axiosBase = axios.create();

axiosBase.interceptors.request.use(function (request) {
  const currentUser = getCurrentUser();

  request.headers.Authorization = currentUser.isSignedIn
    ? `Bearer ${currentUser.accessToken}`
    : "";

  return request;
});

axiosBase.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      toast.warn("401 - Unauthorized");
      dispatch(logoutUser());
    } else if (error.response.status === 500) {
      toast.error("500 - Network Error");
    } else {
      error.response.data.forEach((message) => {
        toast.error(message.errorMessage);
      });
    }
    return Promise.reject(error);
  }
);

function extractData(response) {
  response.data.messages.forEach((message) => {
    toast.success(message.messageText);
  });
  return response.data;
}

class ApiBase {
  get(url, params, headers = {}) {
    return axiosBase.get(url, { params, headers }).then((res) => {
      return extractData(res);
    });
  }

  post(url, body = null, headers = {}) {
    return axiosBase.post(url, body, { headers }).then((res) => {
      return extractData(res);
    });
  }

  put(url, body = null, headers = {}) {
    return axiosBase.put(url, body, { headers }).then((res) => {
      return extractData(res);
    });
  }

  delete(url, params, headers = {}) {
    return axiosBase.delete(url, { params, headers }).then((res) => {
      return extractData(res);
    });
  }

  file(url, body = null, headers = {}) {
    const formData = new FormData();
    formData.append("file", body);

    return axiosBase
      .post(url, formData, { responseType: "blob", headers: headers })
      .then((res) => {
        return extractData(res);
      });
  }
}

export default new ApiBase();
