import {
  GET_PARTNER_INDUSTRIES_DIRECTORY,
  GET_PARTNER_INDUSTRIES_DIRECTORY_ERROR,
  GET_PARTNER_INDUSTRIES_DIRECTORY_SUCCESS
} from "../../actions/directories/partnerIndustriesDirectory.actions";

const initialState = {
  error: null,
  partnerIndustries: [],
  loading: false,
  loaded: false
};

export default function partnerIndustriesDirectoryReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PARTNER_INDUSTRIES_DIRECTORY:
      return {...state, loading: true, loaded: false};
    case GET_PARTNER_INDUSTRIES_DIRECTORY_SUCCESS:
      return {...state, partnerIndustries: action.payload.entities, loading: false, loaded: true};
    case GET_PARTNER_INDUSTRIES_DIRECTORY_ERROR:
      return {...state, loading: false, loaded: false, error: action.error};
    default:
      return state;
  }
}
