import React from "react";
import { Button, Table } from "react-bootstrap";
import PropTypes from "prop-types";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function SuppliersList({ suppliersList, showEdit }) {
  return (
    <Table bordered hover>
      <thead>
        <tr>
          <th>Name</th>
          <th>Company Code</th>
          <th>VAT</th>
          <th>Address</th>
          <th>Phone</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {suppliersList.map((value, index) => {
          return (
            <tr key={index}>
              <td>{value.name}</td>
              <td>{value.companyCode}</td>
              <td>{value.vat}</td>
              <td>{value.address}</td>
              <td>{value.phone}</td>
              <td>
                <Button
                  className={"btn btn-accent btn-sm p-2 mr-1"}
                  onClick={() => showEdit(value)}
                >
                  <FontAwesomeIcon icon={faPencilAlt} />
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

SuppliersList.propTypes = {
  suppliersList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      companyCode: PropTypes.string,
      vat: PropTypes.string,
      address: PropTypes.string,
      phone: PropTypes.string,
    })
  ).isRequired,
};
