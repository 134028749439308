import {
  CREATE_CONTACT,
  CREATE_CONTACT_ERROR,
  CREATE_CONTACT_SUCCESS,
  GET_CONTACT,
  GET_CONTACT_ERROR,
  GET_CONTACT_SUCCESS,
  GET_CONTACTS,
  GET_CONTACTS_ERROR,
  GET_CONTACTS_SUCCESS,
  RESET_SELECTED_CONTACT,
  UPDATE_CONTACT,
  UPDATE_CONTACT_ERROR,
  UPDATE_CONTACT_SUCCESS,
} from "./actions";

const initialContactsState = {
  error: null,
  contacts: {
    total: 0,
    entities: [],
  },
  selectedContact: {},
  loading: false,
  loaded: false,
};

function contactsReducer(state = initialContactsState, action) {
  switch (action.type) {
    case GET_CONTACTS:
      return { ...state, loading: true, loaded: false };
    case GET_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: action.payload,
        loading: false,
        loaded: true,
      };
    case GET_CONTACTS_ERROR:
      return { ...state, loading: false, loaded: false, error: action.error };
    case GET_CONTACT:
      return { ...state, loading: true, loaded: false };
    case GET_CONTACT_SUCCESS:
      return {
        ...state,
        selectedContact: action.payload,
        loading: false,
        loaded: true,
      };
    case GET_CONTACT_ERROR:
      return { ...state, loading: false, loaded: false, error: action.error };
    case UPDATE_CONTACT:
      return { ...state, loading: true, loaded: false };
    case UPDATE_CONTACT_SUCCESS:
      return { ...state, loading: false, loaded: false, error: action.error };
    case UPDATE_CONTACT_ERROR:
      return { ...state, loading: false, loaded: false, error: action.error };
    case CREATE_CONTACT:
      return { ...state, loading: true, loaded: false };
    case CREATE_CONTACT_SUCCESS:
      return { ...state, loading: false, loaded: true };
    case CREATE_CONTACT_ERROR:
      return { ...state, loading: false, loaded: false, error: action.error };
    case RESET_SELECTED_CONTACT:
      return {
        ...state,
        selectedContact: initialContactsState.selectedContact,
      };
    default:
      return state;
  }
}

export default contactsReducer;
