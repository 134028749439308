import {
  GET_ROLES_DIRECTORY,
  GET_ROLES_DIRECTORY_ERROR,
  GET_ROLES_DIRECTORY_SUCCESS,
} from "../../actions/directories/rolesDirectory.actions";

const initialState = {
  error: null,
  roles: [],
  loading: false,
  loaded: false,
};

export function rolesDirectoryReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ROLES_DIRECTORY:
      return { ...state, loading: true, loaded: false };
    case GET_ROLES_DIRECTORY_SUCCESS:
      return {
        ...state,
        roles: action.payload.entities,
        loading: false,
        loaded: true,
      };
    case GET_ROLES_DIRECTORY_ERROR:
      return { ...state, loading: false, loaded: false, error: action.error };
    default:
      return state;
  }
}
