import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createPart, getParts, updatePart } from "./store/actions";
import { Parts } from "./Parts";

export function PartsContainer() {
  // Pagination
  const [, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  // Queries
  const parts = useSelector((state) => state.parts.parts);

  // Dispatches
  const dispatch = useDispatch();
  const onGetParts = (payload) => dispatch(getParts(payload));
  const onUpdatePart = (payload) => dispatch(updatePart(payload));
  const onCreatePart = (payload) => dispatch(createPart(payload));

  const offsetChanged = (offset) => {
    setOffset(offset);
    onGetParts({ offset, take: pageSize });
  };

  const update = (part) => onUpdatePart(part);
  const create = (part) => onCreatePart(part);

  return (
    <Parts
      parts={parts}
      create={create}
      update={update}
      pageSize={pageSize}
      offsetChanged={offsetChanged}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
    />
  );
}
