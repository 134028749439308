import { combineReducers } from "redux";
import addressTypesDirectoryReducer from "./addressTypesDirectory.reducer";
import contactTypesDirectoryReducer from "./contactTypesDirectory.reducer";
import countriesDirectoryReducer from "./countriesDirectory.reducer";
import departmentsDirectoryReducer from "./departmentsDirectory.reducer";
import partnerTypesDirectoryReducer from "./partnerTypesDirectory.reducer";
import partnerIndustriesDirectoryReducer from "./partnerIndustriesDirectory.reducer";
import jobsDirectoryReducer from "./jobsDirectory.reducer";
import employeesDirectoryReducer from "./employeesDirectory.reducer";
import partnersDirectoryReducer from "./partnersDirectory.reducer";
import teamsDirectoryReducer from "./teamsDirectory.reducer";
import contractsDirectoryReducer from "./contractsDirectory.reducer";
import { rolesDirectoryReducer } from "./rolesDirectory.reducer";
import { contactsDirectoryReducer } from "./contactsDirectory.reducer";
import { suppliersDirectoryReducer } from "./suppliersDirectory.reducer";

export default combineReducers({
  addressTypes: addressTypesDirectoryReducer,
  contactTypes: contactTypesDirectoryReducer,
  countries: countriesDirectoryReducer,
  departments: departmentsDirectoryReducer,
  partnerIndustries: partnerIndustriesDirectoryReducer,
  partnerTypes: partnerTypesDirectoryReducer,
  contacts: contactsDirectoryReducer,
  jobs: jobsDirectoryReducer,
  employees: employeesDirectoryReducer,
  partners: partnersDirectoryReducer,
  teams: teamsDirectoryReducer,
  contracts: contractsDirectoryReducer,
  roles: rolesDirectoryReducer,
  suppliers: suppliersDirectoryReducer,
});
