import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../../common/api/api.base";
import { toast } from "react-toastify";
import {
  GET_CONTACT_TYPES_DIRECTORY,
  getContactTypesDirectoryError,
  getContactTypesDirectorySuccess,
} from "../../actions/directories/contactTypesDirectory.actions";

function* workerGetContactTypesDirectory() {
  try {
    const result = yield call(ApiBase.get, `/api/directories/contact-types`);
    if (!result.error) {
      yield put(getContactTypesDirectorySuccess(result.value));
    } else {
      yield put(getContactTypesDirectoryError(`Failed to get contacts!`));
    }
  } catch (e) {
    yield put(getContactTypesDirectoryError(e.message));
  }
}

export default function* watchContactTypesDirectorySaga() {
  yield takeEvery(GET_CONTACT_TYPES_DIRECTORY, workerGetContactTypesDirectory);
}
