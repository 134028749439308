import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactCrop from "react-image-crop";
import PropTypes from "prop-types";
import { getCroppedImgBase64 } from "../../../common/helpers/cropperHelper";
import Avatar from "react-avatar-edit";
import Col from "react-bootstrap/cjs/Col";
import Row from "react-bootstrap/Row";

function CropperModal({ handleClose, handleImageCropped, show, previewImg }) {
  const [crop, setCrop] = useState(null);

  const handleSubmitCrop = () => {
    const image = crop;
    handleImageCropped(image);
    handleClose();
  };

  const closeModal = () => {
    handleClose();
  };

  return (
    <Modal size={"xl"} show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Select area</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Avatar
          width={1000}
          height={500}
          onCrop={(newCrop) => setCrop(newCrop)}
          src={previewImg}
        />
        {crop ? (
          <img
            className="fluid"
            height={250}
            width={250}
            src={crop}
            alt={"Cropped"}
          />
        ) : (
          ""
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="accent" onClick={handleSubmitCrop}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

CropperModal.propTypes = {
  handleClose: PropTypes.func,
  handleImageCropped: PropTypes.func,
};

export default CropperModal;
