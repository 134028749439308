export const GET_CONTACT_TYPES_DIRECTORY = "GET_CONTACT_TYPES_DIRECTORY";
export const GET_CONTACT_TYPES_DIRECTORY_SUCCESS = "GET_CONTACT_TYPES_DIRECTORY_SUCCESS";
export const GET_CONTACT_TYPES_DIRECTORY_ERROR = "GET_CONTACT_TYPES_DIRECTORY_ERROR";

export function getContactTypesDirectory() {
    return {
        type: GET_CONTACT_TYPES_DIRECTORY
    };
}

export function getContactTypesDirectorySuccess(payload) {
    return {
        type: GET_CONTACT_TYPES_DIRECTORY_SUCCESS,
        payload
    };
}

export function getContactTypesDirectoryError(error) {
    return {
        type: GET_CONTACT_TYPES_DIRECTORY_ERROR,
        error
    };
}
