import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSupplier, getSuppliers, updateSupplier } from "./store/actions";
import { Suppliers } from "./Suppliers";

export function SuppliersContainer() {
  // Pagination
  const [, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  // Queries
  const suppliers = useSelector((state) => state.suppliers.suppliers);

  // Dispatches
  const dispatch = useDispatch();
  const onGetSuppliers = (payload) => dispatch(getSuppliers(payload));
  const onUpdateSupplier = (payload) => dispatch(updateSupplier(payload));
  const onCreateSupplier = (payload) => dispatch(createSupplier(payload));

  const offsetChanged = (offset) => {
    setOffset(offset);
    onGetSuppliers({ offset, take: pageSize });
  };

  const update = (supplier) => onUpdateSupplier(supplier);
  const create = (supplier) => onCreateSupplier(supplier);

  return (
    <Suppliers
      suppliers={suppliers}
      create={create}
      update={update}
      pageSize={pageSize}
      offsetChanged={offsetChanged}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
    />
  );
}
