import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../../common/api/api.base";
import {
  GET_ROLES_DIRECTORY,
  getRolesDirectoryError,
  getRolesDirectorySuccess,
} from "../../actions/directories/rolesDirectory.actions";

function* workerGetRolesDirectory(action) {
  try {
    const result = yield call(
      ApiBase.get,
      `/api/directories/roles`,
      action.payload
    );
    if (!result.error) {
      yield put(getRolesDirectorySuccess(result.value));
    } else {
      yield put(getRolesDirectoryError(`Failed to GetRolesDirectory!`));
    }
  } catch (e) {
    yield put(getRolesDirectoryError(e.message));
  }
}

export function* watchRolesDirectorySaga() {
  yield takeEvery(GET_ROLES_DIRECTORY, workerGetRolesDirectory);
}
