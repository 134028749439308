export const GET_ROLES_DIRECTORY = "GET_ROLES_DIRECTORY";
export const GET_ROLES_DIRECTORY_SUCCESS = "GET_ROLES_DIRECTORY_SUCCESS";
export const GET_ROLES_DIRECTORY_ERROR = "GET_ROLES_DIRECTORY_ERROR";

export function getRolesDirectory() {
  return {
    type: GET_ROLES_DIRECTORY,
  };
}

export function getRolesDirectorySuccess(payload) {
  return {
    type: GET_ROLES_DIRECTORY_SUCCESS,
    payload,
  };
}

export function getRolesDirectoryError(error) {
  return {
    type: GET_ROLES_DIRECTORY_ERROR,
    error,
  };
}
