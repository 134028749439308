import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import useForm from "react-hook-form";
import PropTypes from "prop-types";

export function PartEditModal({
  handleClose,
  handleUpdate,
  show,
  selectedPart,
}) {
  const { register, handleSubmit, setValue, errors } = useForm({
    defaultValues: selectedPart,
  });

  const handleSubmitUpdate = (values) => {
    handleUpdate({ ...values, id: selectedPart.id });
    handleClose();
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Edit Part {selectedPart && selectedPart.partNumber}
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(handleSubmitUpdate)}>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label htmlFor={"name"}>Name</Form.Label>
                  <Form.Control
                    type={"text"}
                    className={errors.name ? "is-invalid" : ""}
                    ref={register({ required: true })}
                    name={"name"}
                    placeholder={"..."}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label htmlFor={"number"}>Part Number</Form.Label>
                  <Form.Control
                    type={"text"}
                    className={errors.number ? "is-invalid" : ""}
                    ref={register({ required: true })}
                    name={"number"}
                    placeholder={"..."}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="accent" type="submit">
              Update
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

PartEditModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};
