import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../../common/api/api.base";
import { toast } from "react-toastify";
import {
  GET_JOBS_DIRECTORY,
  getJobsDirectoryError,
  getJobsDirectorySuccess,
} from "../../actions/directories/jobsDirectory.actions";

function* workerGetJobsDirectory(action) {
  try {
    const result = yield call(
      ApiBase.get,
      `/api/directories/jobs`,
      action.payload
    );
    if (!result.error) {
      yield put(getJobsDirectorySuccess(result.value));
    } else {
      yield put(getJobsDirectoryError(`Failed to get GetJobsDirectory!`));
    }
  } catch (e) {
    yield put(getJobsDirectoryError(e.message));
  }
}

export default function* watchJobsDirectorySaga() {
  yield takeEvery(GET_JOBS_DIRECTORY, workerGetJobsDirectory);
}
