import {
    CREATE_EMPLOYEE,
    CREATE_EMPLOYEE_ERROR,
    CREATE_EMPLOYEE_SUCCESS,
    GET_EMPLOYEES,
    GET_EMPLOYEES_ERROR,
    GET_EMPLOYEES_SUCCESS,
    UPDATE_EMPLOYEE,
    UPDATE_EMPLOYEE_ERROR,
    UPDATE_EMPLOYEE_SUCCESS
} from "./actions";

const initialState = {
  error: null,
  employees: {
    total: 0,
    entities: []
  },
  loading: false,
  loaded: false
};

export default function employeesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEES:
      return {...state, loading: true, loaded: false};
    case GET_EMPLOYEES_SUCCESS:
      return {...state, employees: action.payload, loading: false, loaded: true};
    case GET_EMPLOYEES_ERROR:
      return {...state, loading: false, loaded: false, error: action.error};

    case CREATE_EMPLOYEE:
      return {...state, loading: true, loaded: false};
    case CREATE_EMPLOYEE_SUCCESS:
      return {...state, loading: false, loaded: true};
    case CREATE_EMPLOYEE_ERROR:
      return {...state, loading: false, loaded: false, error: action.error};

    case UPDATE_EMPLOYEE:
      return {...state, loading: true, loaded: false};
    case UPDATE_EMPLOYEE_SUCCESS:
      return {...state, loading: false, loaded: true};
    case UPDATE_EMPLOYEE_ERROR:
      return {...state, loading: false, loaded: false, error: action.error};
    default:
      return state;
  }
}
