import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { Card } from "react-bootstrap";

function DashboardBarChart(props) {
  return (
    <>
      <Card style={{ height: "300px" }}>
        <Card.Body style={{ padding: 0 }}>
          <ResponsiveBar
            data={data}
            keys={["hot dog", "burger", "sandwich", "kebab", "fries", "donut"]}
            indexBy="country"
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            colors={{ scheme: "nivo" }}
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "#38bcb2",
                size: 4,
                padding: 1,
                stagger: true
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "#bfeec2",
                rotation: -45,
                lineWidth: 6,
                spacing: 10
              }
            ]}
            fill={[
              {
                match: {
                  id: "fries"
                },
                id: "dots"
              },
              {
                match: {
                  id: "sandwich"
                },
                id: "lines"
              }
            ]}
            borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "country",
              legendPosition: "middle",
              legendOffset: 32
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "food",
              legendPosition: "middle",
              legendOffset: -40
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ]}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
          />
        </Card.Body>
      </Card>
    </>
  );
}

export default DashboardBarChart;

const data = [
  {
    country: "AD",
    "hot dog": 111,
    "hot dogColor": "hsl(269, 70%, 50%)",
    burger: 89,
    burgerColor: "hsl(111, 70%, 50%)",
    sandwich: 178,
    sandwichColor: "hsl(248, 70%, 50%)",
    kebab: 2,
    kebabColor: "hsl(191, 70%, 50%)",
    fries: 62,
    friesColor: "hsl(264, 70%, 50%)",
    donut: 1,
    donutColor: "hsl(81, 70%, 50%)"
  },
  {
    country: "AE",
    "hot dog": 185,
    "hot dogColor": "hsl(145, 70%, 50%)",
    burger: 157,
    burgerColor: "hsl(216, 70%, 50%)",
    sandwich: 99,
    sandwichColor: "hsl(144, 70%, 50%)",
    kebab: 171,
    kebabColor: "hsl(34, 70%, 50%)",
    fries: 22,
    friesColor: "hsl(134, 70%, 50%)",
    donut: 102,
    donutColor: "hsl(182, 70%, 50%)"
  },
  {
    country: "AF",
    "hot dog": 8,
    "hot dogColor": "hsl(233, 70%, 50%)",
    burger: 188,
    burgerColor: "hsl(325, 70%, 50%)",
    sandwich: 163,
    sandwichColor: "hsl(25, 70%, 50%)",
    kebab: 111,
    kebabColor: "hsl(40, 70%, 50%)",
    fries: 9,
    friesColor: "hsl(167, 70%, 50%)",
    donut: 32,
    donutColor: "hsl(250, 70%, 50%)"
  },
  {
    country: "AG",
    "hot dog": 89,
    "hot dogColor": "hsl(254, 70%, 50%)",
    burger: 2,
    burgerColor: "hsl(327, 70%, 50%)",
    sandwich: 57,
    sandwichColor: "hsl(230, 70%, 50%)",
    kebab: 0,
    kebabColor: "hsl(2, 70%, 50%)",
    fries: 31,
    friesColor: "hsl(6, 70%, 50%)",
    donut: 169,
    donutColor: "hsl(31, 70%, 50%)"
  },
  {
    country: "AI",
    "hot dog": 176,
    "hot dogColor": "hsl(153, 70%, 50%)",
    burger: 53,
    burgerColor: "hsl(239, 70%, 50%)",
    sandwich: 179,
    sandwichColor: "hsl(338, 70%, 50%)",
    kebab: 153,
    kebabColor: "hsl(333, 70%, 50%)",
    fries: 88,
    friesColor: "hsl(239, 70%, 50%)",
    donut: 19,
    donutColor: "hsl(262, 70%, 50%)"
  },
  {
    country: "AL",
    "hot dog": 6,
    "hot dogColor": "hsl(329, 70%, 50%)",
    burger: 138,
    burgerColor: "hsl(331, 70%, 50%)",
    sandwich: 193,
    sandwichColor: "hsl(219, 70%, 50%)",
    kebab: 178,
    kebabColor: "hsl(127, 70%, 50%)",
    fries: 10,
    friesColor: "hsl(90, 70%, 50%)",
    donut: 30,
    donutColor: "hsl(271, 70%, 50%)"
  },
  {
    country: "AM",
    "hot dog": 69,
    "hot dogColor": "hsl(65, 70%, 50%)",
    burger: 105,
    burgerColor: "hsl(192, 70%, 50%)",
    sandwich: 63,
    sandwichColor: "hsl(316, 70%, 50%)",
    kebab: 33,
    kebabColor: "hsl(145, 70%, 50%)",
    fries: 56,
    friesColor: "hsl(301, 70%, 50%)",
    donut: 134,
    donutColor: "hsl(110, 70%, 50%)"
  }
];
