import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import {PropTypes} from "prop-types";
import styled from "styled-components";
import img from "../../../../assets/img/auth-background.jpg";

const Wrapper = styled.div`
  height: 100%;
  background:url(${img}) no-repeat center !important;
`;

function ScreenCenterLayout(props) {
  return (
    <Wrapper className="container-fluid h-100">
      <Row className="row h-100">
        <Col/>
        <Col className="flex-column align-middle d-flex justify-content-center">
          <Card bg={props.cardColor} text={props.cardTextColor}>
            <Card.Header>
              {props.cardHeaderContent}
            </Card.Header>
            <Card.Body>
              {props.cardBodyContent}
              <Card.Title>
                {props.cardBodyTitle}
              </Card.Title>
              <Card.Text>
                {props.cardBodyText}
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              {props.cardFooterContent}
            </Card.Footer>
          </Card>
        </Col>
        <Col/>
      </Row>
    </Wrapper>
  );
}

ScreenCenterLayout.propTypes = {
  cardBodyTitle: PropTypes.string,
  cardHeaderContent: PropTypes.object,
  cardBodyContent: PropTypes.object,
  cardBodyText: PropTypes.string,
  cardFooterContent: PropTypes.string,
  cardColor: PropTypes.string.isRequired,
  cardTextColor: PropTypes.string.isRequired,
};

ScreenCenterLayout.defaultProps = {
  cardColor: "danger",
  cardTextColor: "white"
};

export default ScreenCenterLayout;
