import React, { useState } from "react";
import { Button, Col, Form, Image, Modal, Row } from "react-bootstrap";
import useForm from "react-hook-form";
import HookDatePicker from "../../../common/components/wrappers/HookDatepicker";
import PropTypes from "prop-types";
import CropperModal from "../../../common/components/CropperModal/CropperModal";
import SingleFileInput from "../../../common/components/FileInput/SingleFileInput";

function ContactCreateModal({ handleClose, handleCreate, show }) {
  const { register, handleSubmit, errors, setValue } = useForm();

  const [showCropper, setShowCropper] = useState(false);
  const handleCloseCropper = () => setShowCropper(false);

  const [fileForPreview, setFileForPreview] = useState(null);
  const [fileForUpload, setFileForUpload] = useState(null);

  const handleSetFileForPreview = (file) => {
    setFileForPreview(URL.createObjectURL(file));
    setShowCropper(true);
  };

  const handleSubmitCreate = (values) => {
    handleCreate({ ...values, avatarData: fileForUpload });
    closeModal();
  };

  const handleImageCropped = (image) => {
    setFileForUpload(image);
  };

  const closeModal = () => {
    handleClose();
    setFileForPreview(null);
  };

  return (
    <>
      <Modal size="lg" show={show && !showCropper} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create Contact</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(handleSubmitCreate)}>
          <Modal.Body>
            <Row>
              <Col md={6} lg={4}>
                <Form.Group>
                  <Form.Label htmlFor={"avatar"}>Avatar</Form.Label>
                  <SingleFileInput
                    showFileUpload={false}
                    fileChanged={(file) => handleSetFileForPreview(file)}
                  />
                </Form.Group>
                {fileForUpload ? (
                  <Image
                    className="fluid"
                    height={250}
                    width={250}
                    src={fileForUpload}
                  />
                ) : null}
              </Col>
              <Col>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label htmlFor={"firstName"}>First Name</Form.Label>
                      <Form.Control
                        type={"text"}
                        className={errors.firstName ? "is-invalid" : ""}
                        ref={register({ required: true })}
                        name={"firstName"}
                        placeholder={"..."}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label htmlFor={"lastName"}>Last Name</Form.Label>
                      <Form.Control
                        type={"text"}
                        className={errors.lastName ? "is-invalid" : ""}
                        ref={register({ required: true })}
                        name={"lastName"}
                        placeholder={"..."}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Birth Date</Form.Label>
                      <HookDatePicker
                        name={"birthDate"}
                        placeholder={"Select date..."}
                        register={register}
                        setValue={setValue}
                        selected={null}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label htmlFor={"phone"}>Phone</Form.Label>
                      <Form.Control
                        type={"text"}
                        ref={register}
                        name={"phone"}
                        placeholder={"..."}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label htmlFor={"email"}>Email</Form.Label>
                      <Form.Control
                        type={"text"}
                        className={errors.email ? "is-invalid" : ""}
                        ref={register({ required: true })}
                        name={"email"}
                        placeholder={"..."}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label htmlFor={"address"}>Address</Form.Label>
                      <Form.Control
                        type={"text"}
                        ref={register}
                        name={"address"}
                        placeholder={"..."}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label htmlFor={"zipCode"}>Zip Code</Form.Label>
                      <Form.Control
                        type={"text"}
                        ref={register}
                        name={"zipCode"}
                        placeholder={"..."}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label htmlFor={"skype"}>Skype</Form.Label>
                      <Form.Control
                        type={"text"}
                        ref={register}
                        name={"skype"}
                        placeholder={"..."}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label htmlFor={"middleName"}>
                        Middle Name
                      </Form.Label>
                      <Form.Control
                        type={"text"}
                        ref={register}
                        name={"middleName"}
                        placeholder={"..."}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="accent" type="submit">
              Create
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <CropperModal
        handleImageCropped={handleImageCropped}
        handleClose={handleCloseCropper}
        show={showCropper}
        previewImg={fileForPreview}
      />
    </>
  );
}

ContactCreateModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleCreate: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default ContactCreateModal;
