import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../../common/api/api.base";
import {
  CREATE_MAIL,
  createMailError,
  createMailSuccess,
  GET_MAILS,
  getMailsError,
  getMailsSuccess,
} from "./actions";

function* workerGetMail(action) {
  try {
    const result = yield call(ApiBase.get, `/api/mails`, action.payload);
    if (!result.error) {
      yield put(getMailsSuccess(result.value));
    } else {
      yield put(getMailsError(`Failed to get Mail!`));
    }
  } catch (e) {
    yield put(getMailsError(e.message));
  }
}

function* workerCreateMail(action) {
  try {
    const result = yield call(ApiBase.post, `/api/mails`, action.payload);
    if (!result.error) {
      yield put(createMailSuccess(result.value));
    } else {
      yield put(createMailError(`Failed to get CreateMail!`));
    }
  } catch (e) {
    yield put(createMailError(e.message));
  }
}

export default function* watchMailSaga() {
  yield takeEvery(GET_MAILS, workerGetMail);
  yield takeEvery(CREATE_MAIL, workerGetMail);
}
