import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../../common/api/api.base";
import { toast } from "react-toastify";
import {
  GET_COUNTRIES_DIRECTORY,
  getCountriesDirectoryError,
  getCountriesDirectorySuccess,
} from "../../actions/directories/countriesDirectory.actions";

function* workerGetCountriesDirectory() {
  try {
    const result = yield call(ApiBase.get, `/api/directories/countries`);
    if (!result.error) {
      yield put(getCountriesDirectorySuccess(result.value));
    } else {
      yield put(getCountriesDirectoryError(`Failed to get countries!`));
    }
  } catch (e) {
    yield put(getCountriesDirectoryError(e.message));
  }
}

export default function* watchCountriesDirectorySaga() {
  yield takeEvery(GET_COUNTRIES_DIRECTORY, workerGetCountriesDirectory);
}
