import {UPDATE_USER_PROFILE, UPDATE_USER_PROFILE_ERROR, UPDATE_USER_PROFILE_SUCCESS} from './actions';

const initialState = {
  error: null,
  profile: {},
  loading: false,
  loaded: false
};

export default function userProfileReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_USER_PROFILE:
      return {...state, loading: true, loaded: false};
    case UPDATE_USER_PROFILE_SUCCESS:
      return {...state, profile: action.payload, loading: false, loaded: true};
    case UPDATE_USER_PROFILE_ERROR:
      return {...state, loading: false, loaded: false, error: action.error};
    default:
      return state;
  }
}
