import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../../common/api/api.base";
import { toast } from "react-toastify";
import {
  GET_ADDRESS_TYPES_DIRECTORY,
  getAddressTypesDirectoryError,
  getAddressTypesDirectorySuccess,
} from "../../actions/directories/addressTypesDirectory.actions";

function* workerGetAddressTypesDirectory() {
  try {
    const result = yield call(ApiBase.get, `/api/directories/address-types`);
    if (!result.error) {
      yield put(getAddressTypesDirectorySuccess(result.value));
    } else {
      yield put(getAddressTypesDirectoryError(`Failed to get contacts!`));
    }
  } catch (e) {
    yield put(getAddressTypesDirectoryError(e.message));
  }
}

export default function* watchAddressTypesDirectorySaga() {
  yield takeEvery(GET_ADDRESS_TYPES_DIRECTORY, workerGetAddressTypesDirectory);
}
