export const GET_EMPLOYEES = "GET_EMPLOYEES";
export const GET_EMPLOYEES_SUCCESS = "GET_EMPLOYEES_SUCCESS";
export const GET_EMPLOYEES_ERROR = "GET_EMPLOYEES_ERROR";

export function getEmployees(payload) {
  return {
    type: GET_EMPLOYEES,
    payload
  };
}

export function getEmployeesSuccess(payload) {
  return {
    type: GET_EMPLOYEES_SUCCESS,
    payload
  };
}

export function getEmployeesError(error) {
  return {
    type: GET_EMPLOYEES_ERROR,
    error
  };
}

export const CREATE_EMPLOYEE = "CREATE_EMPLOYEE";
export const CREATE_EMPLOYEE_SUCCESS = "CREATE_EMPLOYEE_SUCCESS";
export const CREATE_EMPLOYEE_ERROR = "CREATE_EMPLOYEE_ERROR";

export function createEmployee(payload) {
  return {
    type: CREATE_EMPLOYEE,
    payload
  };
}

export function createEmployeeSuccess(payload) {
  return {
    type: CREATE_EMPLOYEE_SUCCESS,
    payload
  };
}

export function createEmployeeError(error) {
  return {
    type: CREATE_EMPLOYEE_ERROR,
    error
  };
}

export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS";
export const UPDATE_EMPLOYEE_ERROR = "UPDATE_EMPLOYEE_ERROR";

export function updateEmployee(payload) {
  return {
    type: UPDATE_EMPLOYEE,
    payload
  };
}

export function updateEmployeeSuccess(payload) {
  return {
    type: UPDATE_EMPLOYEE_SUCCESS,
    payload
  };
}

export function updateEmployeeError(error) {
  return {
    type: UPDATE_EMPLOYEE_ERROR,
    error
  };
}
