import React from "react";
import styled from "styled-components";
import img from "../../assets/img/auth-background.jpg";
import { Card, Container, Row } from "react-bootstrap";
import AuthForm from "./components/AuthForm";

const Wrapper = styled.div`
  height: 100%;
  background: url(${img}) no-repeat center !important;
`;

const Logo = styled.div`
  text-align: center;
  margin-top: -20%;
`;

const LogoImage = styled.img`
  background-color: #ffffff;
  border-radius: 6rem;
  width: 27%;
`;

const StyledCard = styled(Card)`
  width: 18rem;
`;

export default function Auth({ loginUser }) {
  return (
    <Wrapper>
      <Container className="h-100">
        <Row className="h-100 justify-content-center align-items-center">
          <StyledCard>
            <Card.Body className="card-body">
              <Logo>
                <LogoImage
                  src="assets/img/logo1-auth.png"
                  alt="rocket_contact"
                />
              </Logo>
              <div className="login-form">
                <div className="main-div">
                  <div className="panel text-center">
                    <h4>Welcome to Legio</h4>
                  </div>
                  <AuthForm onLogin={loginUser} />
                </div>
              </div>
            </Card.Body>
          </StyledCard>
        </Row>
      </Container>
    </Wrapper>
  );
}
