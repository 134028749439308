import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../../common/api/api.base";
import {
  UPDATE_USER_PROFILE,
  updateUserProfileError,
  updateUserProfileSuccess,
} from "./actions";

function* workerUpdateUserProfile(action) {
  try {
    const result = yield call(ApiBase.put, `/api/profile`, action.payload);
    if (!result.error) {
      yield put(updateUserProfileSuccess(result.value));
    } else {
      yield put(updateUserProfileError(`Failed to get contacts!`));
    }
  } catch (e) {
    yield put(updateUserProfileError(e.message));
  }
}

export default function* watchUserProfileSaga() {
  yield takeEvery(UPDATE_USER_PROFILE, workerUpdateUserProfile);
}
