export const GET_PARTNER_TYPES_DIRECTORY = "GET_PARTNER_TYPES_DIRECTORY";
export const GET_PARTNER_TYPES_DIRECTORY_SUCCESS = "GET_PARTNER_TYPES_DIRECTORY_SUCCESS";
export const GET_PARTNER_TYPES_DIRECTORY_ERROR = "GET_PARTNER_TYPES_DIRECTORY_ERROR";

export function getPartnerTypesDirectory() {
    return {
        type: GET_PARTNER_TYPES_DIRECTORY
    };
}

export function getPartnerTypesDirectorySuccess(payload) {
    return {
        type: GET_PARTNER_TYPES_DIRECTORY_SUCCESS,
        payload
    };
}

export function getPartnerTypesDirectoryError(error) {
    return {
        type: GET_PARTNER_TYPES_DIRECTORY_ERROR,
        error
    };
}
