import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import useForm from "react-hook-form";
import PropTypes from "prop-types";
import HookSelect from "../../../../common/components/wrappers/HookSelect";

export function StockPartEditModal({
  handleClose,
  handleUpdate,
  show,
  suppliers,
  selectedStockPart,
}) {
  const { register, handleSubmit, setValue, errors } = useForm({
    defaultValues: selectedStockPart,
  });

  const handleSubmitUpdate = (values) => {
    handleUpdate({ ...values, id: selectedStockPart.id });
    handleClose();
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            Edit stock {selectedStockPart && selectedStockPart.partNumber}
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(handleSubmitUpdate)}>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label htmlFor={"row"}>Row</Form.Label>
                  <Form.Control
                    type={"number"}
                    className={errors.row ? "is-invalid" : ""}
                    ref={register({ required: true })}
                    name={"row"}
                    placeholder={"..."}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label htmlFor={"shelf"}>Shelf</Form.Label>
                  <Form.Control
                    type={"number"}
                    className={errors.shelf ? "is-invalid" : ""}
                    ref={register({ required: true })}
                    name={"shelf"}
                    placeholder={"..."}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label htmlFor={"quantity"}>Quantity</Form.Label>
                  <Form.Control
                    type={"number"}
                    className={errors.quantity ? "is-invalid" : ""}
                    ref={register({ required: true })}
                    name={"quantity"}
                    placeholder={"..."}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="accent" type="submit">
              Update
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

StockPartEditModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};
