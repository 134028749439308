import React from "react";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/actions/auth.actions";
import Auth from "./Auth";

function AuthContainer() {
  const dispatch = useDispatch();
  const onLogin = payload => dispatch(loginUser(payload));

  return <Auth loginUser={onLogin} />;
}

export default AuthContainer;
