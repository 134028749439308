export const GET_SUPPLIERS = "GET_SUPPLIERS";
export const GET_SUPPLIERS_SUCCESS = "GET_SUPPLIERS_SUCCESS";
export const GET_SUPPLIERS_ERROR = "GET_SUPPLIERS_ERROR";

export function getSuppliers(payload) {
    return {
        type: GET_SUPPLIERS,
        payload
    };
}

export function getSuppliersSuccess(payload) {
    return {
        type: GET_SUPPLIERS_SUCCESS,
        payload
    };
}

export function getSuppliersError(error) {
    return {
        type: GET_SUPPLIERS_ERROR,
        error
    };
}

export const CREATE_SUPPLIER = "CREATE_SUPPLIER";
export const CREATE_SUPPLIER_SUCCESS = "CREATE_SUPPLIER_SUCCESS";
export const CREATE_SUPPLIER_ERROR = "CREATE_SUPPLIER_ERROR";

export function createSupplier(payload) {
    return {
        type: CREATE_SUPPLIER,
        payload
    };
}

export function createSupplierSuccess(payload) {
    return {
        type: CREATE_SUPPLIER_SUCCESS,
        payload
    };
}

export function createSupplierError(error) {
    return {
        type: CREATE_SUPPLIER_ERROR,
        error
    };
}

export const UPDATE_SUPPLIER = "UPDATE_SUPPLIER";
export const UPDATE_SUPPLIER_SUCCESS = "UPDATE_SUPPLIER_SUCCESS";
export const UPDATE_SUPPLIER_ERROR = "UPDATE_SUPPLIER_ERROR";

export function updateSupplier(payload) {
    return {
        type: UPDATE_SUPPLIER,
        payload
    };
}

export function updateSupplierSuccess(payload) {
    return {
        type: UPDATE_SUPPLIER_SUCCESS,
        payload
    };
}

export function updateSupplierError(error) {
    return {
        type: UPDATE_SUPPLIER_ERROR,
        error
    };
}