export const GET_MAILS = "GET_MAILS";
export const GET_MAILS_SUCCESS = "GET_MAILS_SUCCESS";
export const GET_MAILS_ERROR = "GET_MAILS_ERROR";

export function getMails(payload) {
  return {
    type: GET_MAILS,
    payload
  };
}

export function getMailsSuccess(payload) {
  return {
    type: GET_MAILS_SUCCESS,
    payload
  };
}

export function getMailsError(error) {
  return {
    type: GET_MAILS_ERROR,
    error
  };
}

export const CREATE_MAIL = "CREATE_MAIL";
export const CREATE_MAIL_SUCCESS = "CREATE_MAIL_SUCCESS";
export const CREATE_MAIL_ERROR = "CREATE_MAIL_ERROR";

export function createMail(payload) {
  return {
    type: CREATE_MAIL
  };
}

export function createMailSuccess(payload) {
  return {
    type: CREATE_MAIL_SUCCESS,
    payload
  };
}

export function createMailError(error) {
  return {
    type: CREATE_MAIL_ERROR,
    error
  };
}
