import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../../common/api/api.base";
import {
  GET_USERS,
  CREATE_USER,
  UPDATE_USER,
  getUsersSuccess,
  getUsersError,
  createUserSuccess,
  createUserError,
  updateUserSuccess,
  updateUserError,
  getUsers,
} from "./actions";

function* workerGetUsers(action) {
  try {
    const result = yield call(ApiBase.get, `/api/users`, action.payload);
    if (!result.error) {
      yield put(getUsersSuccess(result.value));
    } else {
      yield put(getUsersError(`Failed to get Users!`));
    }
  } catch (e) {
    yield put(getUsersError(e.message));
  }
}

function* workerCreateUser(action) {
  try {
    const result = yield call(ApiBase.post, `/api/users`, action.payload);
    if (!result.error) {
      yield put(createUserSuccess(result.value));
      yield put(getUsers({ take: 10, offset: 0 }));
    } else {
      yield put(createUserError(`Failed to create Users!`));
    }
  } catch (e) {
    yield put(createUserError(e.message));
  }
}

function* workerUpdateUser(action) {
  try {
    const result = yield call(ApiBase.put, `/api/users`, action.payload);
    if (!result.error) {
      yield put(updateUserSuccess(result.value));
      yield put(getUsers({ take: 10, offset: 0 }));
    } else {
      yield put(updateUserError(`Failed to update Users!`));
    }
  } catch (e) {
    yield put(updateUserError(e.message));
  }
}

export function* watchUsersSaga() {
  yield takeEvery(GET_USERS, workerGetUsers);
  yield takeEvery(CREATE_USER, workerCreateUser);
  yield takeEvery(UPDATE_USER, workerUpdateUser);
}
