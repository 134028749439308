export const GET_PARTS = "GET_PARTS";
export const GET_PARTS_SUCCESS = "GET_PARTS_SUCCESS";
export const GET_PARTS_ERROR = "GET_PARTS_ERROR";

export function getParts(payload) {
    return {
        type: GET_PARTS,
        payload
    };
}

export function getPartsSuccess(payload) {
    return {
        type: GET_PARTS_SUCCESS,
        payload
    };
}

export function getPartsError(error) {
    return {
        type: GET_PARTS_ERROR,
        error
    };
}

export const CREATE_PART = "CREATE_PART";
export const CREATE_PART_SUCCESS = "CREATE_PART_SUCCESS";
export const CREATE_PART_ERROR = "CREATE_PART_ERROR";

export function createPart(payload) {
    return {
        type: CREATE_PART,
        payload
    };
}

export function createPartSuccess(payload) {
    return {
        type: CREATE_PART_SUCCESS,
        payload
    };
}

export function createPartError(error) {
    return {
        type: CREATE_PART_ERROR,
        error
    };
}

export const UPDATE_PART = "UPDATE_PART";
export const UPDATE_PART_SUCCESS = "UPDATE_PART_SUCCESS";
export const UPDATE_PART_ERROR = "UPDATE_PART_ERROR";

export function updatePart(payload) {
    return {
        type: UPDATE_PART,
        payload
    };
}

export function updatePartSuccess(payload) {
    return {
        type: UPDATE_PART_SUCCESS,
        payload
    };
}

export function updatePartError(error) {
    return {
        type: UPDATE_PART_ERROR,
        error
    };
}