/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from "react-datepicker";
import {parseDateToString, parseStringToDate} from "../../helpers/transformers/datesHelper";
import styled from "styled-components";

function HookDatePicker({selected = null, name, placeholder, register, setValue, additionalClasses = 'form-control', format = "MM/dd/yyyy", ...otherProps}) {
  const [date, setDate] = useState(parseStringToDate(selected));
  const [formattedDate, setFormattedDate] = useState(null);

  useEffect(() => {
    register({name: name});
  }, []);

  useEffect(() => {
    setFormattedDate(parseDateToString(date, format));
  }, [date]);

  useEffect(() => {
    setValue(name, formattedDate);
  }, [formattedDate]);

  const handleDateChange = newDate => {
    setDate(newDate);
  };

  return (
    <DatepickerWrapper>
      <ReactDatePicker
        selected={date}
        onChange={handleDateChange}
        placeholderText={placeholder}
        className={additionalClasses}
        dateFormat={format}
        handleDateChange
        dayClassName={() => 'day-success'}
        {...otherProps}
      />
    </DatepickerWrapper>
  );
}

const DatepickerWrapper = styled.div`
> div.react-datepicker-wrapper, 
> div > div.react-datepicker__input-container
> div > div.react-datepicker__input-container input {
   width: 100%;
}
.react-datepicker__day--selected {
  background-color: #43AA8B; !important;
  :hover {
  background-color: #4cc09d; !important;
  }
}
`;

HookDatePicker.propTypes = {
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default HookDatePicker;
