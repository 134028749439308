import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../../common/api/api.base";
import { toast } from "react-toastify";
import {
  GET_EMPLOYEES_DIRECTORY,
  getEmployeesDirectoryError,
  getEmployeesDirectorySuccess,
} from "../../actions/directories/employeesDirectory.actions";

function* workerGetEmployeesDirectory(action) {
  try {
    const result = yield call(
      ApiBase.get,
      `/api/directories/employees`,
      action.payload
    );
    if (!result.error) {
      yield put(getEmployeesDirectorySuccess(result.value));
    } else {
      yield put(
        getEmployeesDirectoryError(`Failed to get GetEmployeesDirectory!`)
      );
    }
  } catch (e) {
    yield put(getEmployeesDirectoryError(e.message));
  }
}

export default function* watchEmployeesDirectorySaga() {
  yield takeEvery(GET_EMPLOYEES_DIRECTORY, workerGetEmployeesDirectory);
}
