import React from "react";
import SharedPageHeader from "../../common/components/blocks/SharedPageHeader/SharedPageHeader";
import DashboardHeader from "./components/DashboardHeader";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import DashboardLineChart from "./components/DashboardLineChart";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faPlus } from "@fortawesome/free-solid-svg-icons";

function Dashboard(props) {
  return (
    <>
      <SharedPageHeader mainText={"Reports"} secondaryText={"Useful reports"}>
        <Button variant="accent" className="btn-sm mb-2">
          <FontAwesomeIcon fixedWidth={true} icon={faFileExcel} />
          Download Report
        </Button>
      </SharedPageHeader>

      <DashboardHeader />

      <Row className={"mt-4"}>
        <Col>
          <DashboardLineChart />
        </Col>
      </Row>
    </>
  );
}

export default Dashboard;
