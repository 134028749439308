import React from "react";
import {Route} from "react-router";

const Teams = React.lazy(() => import("./Teams/Teams.container"));
const Employees = React.lazy(() => import("./Employees/Employees.container"));


function WorkRouting({match, nestedPath}) {
  return (
    <>
      <Route path={`${match.url}/${nestedPath}/employees`} component={Employees}/>
      <Route path={`${match.url}/${nestedPath}/teams`} component={Teams}/>
    </>
  );
}

export default WorkRouting;
