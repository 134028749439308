export const GET_EMPLOYEES_DIRECTORY = "GET_EMPLOYEES_DIRECTORY";
export const GET_EMPLOYEES_DIRECTORY_SUCCESS = "GET_EMPLOYEES_DIRECTORY_SUCCESS";
export const GET_EMPLOYEES_DIRECTORY_ERROR = "GET_EMPLOYEES_DIRECTORY_ERROR";

export function getEmployeesDirectory() {
    return {
        type: GET_EMPLOYEES_DIRECTORY,

    };
}

export function getEmployeesDirectorySuccess(payload) {
    return {
        type: GET_EMPLOYEES_DIRECTORY_SUCCESS,
        payload
    };
}

export function getEmployeesDirectoryError(error) {
    return {
        type: GET_EMPLOYEES_DIRECTORY_ERROR,
        error
    };
}
