import {
  CREATE_SUPPLIER,
  CREATE_SUPPLIER_ERROR,
  CREATE_SUPPLIER_SUCCESS,
  GET_SUPPLIERS,
  GET_SUPPLIERS_ERROR,
  GET_SUPPLIERS_SUCCESS,
  UPDATE_SUPPLIER,
  UPDATE_SUPPLIER_ERROR,
  UPDATE_SUPPLIER_SUCCESS,
} from "./actions";

const initialState = {
  error: null,
  suppliers: {
    total: 0,
    entities: [],
  },
  loading: false,
  loaded: false,
};

export function suppliersReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUPPLIERS:
      return { ...state, loading: true, loaded: false };
    case GET_SUPPLIERS_SUCCESS:
      return {
        ...state,
        suppliers: action.payload,
        loading: false,
        loaded: true,
      };
    case GET_SUPPLIERS_ERROR:
      return { ...state, loading: false, loaded: false, error: action.error };
    case CREATE_SUPPLIER:
      return { ...state, loading: true, loaded: false };
    case CREATE_SUPPLIER_SUCCESS:
      return { ...state, loading: false, loaded: true };
    case CREATE_SUPPLIER_ERROR:
      return { ...state, loading: false, loaded: false, error: action.error };
    case UPDATE_SUPPLIER:
      return { ...state, loading: true, loaded: false };
    case UPDATE_SUPPLIER_SUCCESS:
      return { ...state, loading: false, loaded: true };
    case UPDATE_SUPPLIER_ERROR:
      return { ...state, loading: false, loaded: false, error: action.error };
    default:
      return state;
  }
}
