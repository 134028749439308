import {
  GET_USERS,
  GET_USERS_ERROR,
  GET_USERS_SUCCESS,
  CREATE_USER,
  CREATE_USER_ERROR,
  CREATE_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
} from "./actions";

const initialState = {
  error: null,
  users: {
    total: 0,
    entities: [],
  },
  loading: false,
  loaded: false,
};

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USERS:
      return { ...state, loading: true, loaded: false };
    case GET_USERS_SUCCESS:
      return { ...state, users: action.payload, loading: false, loaded: true };
    case GET_USERS_ERROR:
      return { ...state, loading: false, loaded: false, error: action.error };
    case CREATE_USER:
      return { ...state, loading: true, loaded: false };
    case CREATE_USER_SUCCESS:
      return { ...state, loading: false, loaded: true };
    case CREATE_USER_ERROR:
      return { ...state, loading: false, loaded: false, error: action.error };
    case UPDATE_USER:
      return { ...state, loading: true, loaded: false };
    case UPDATE_USER_SUCCESS:
      return { ...state, loading: false, loaded: true };
    case UPDATE_USER_ERROR:
      return { ...state, loading: false, loaded: false, error: action.error };
    default:
      return state;
  }
}
