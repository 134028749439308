export const GET_JOBS_DIRECTORY = "GET_JOBS_DIRECTORY";
export const GET_JOBS_DIRECTORY_SUCCESS = "GET_JOBS_DIRECTORY_SUCCESS";
export const GET_JOBS_DIRECTORY_ERROR = "GET_JOBS_DIRECTORY_ERROR";

export function getJobsDirectory() {
    return {
        type: GET_JOBS_DIRECTORY,

    };
}

export function getJobsDirectorySuccess(payload) {
    return {
        type: GET_JOBS_DIRECTORY_SUCCESS,
        payload
    };
}

export function getJobsDirectoryError(error) {
    return {
        type: GET_JOBS_DIRECTORY_ERROR,
        error
    };
}
