import {
  CREATE_STOCK_PART,
  CREATE_STOCK_PART_ERROR,
  CREATE_STOCK_PART_SUCCESS,
  GET_STOCK,
  GET_STOCK_ERROR,
  GET_STOCK_SUCCESS,
  UPDATE_STOCK,
  UPDATE_STOCK_ERROR,
  UPDATE_STOCK_SUCCESS,
} from "./actions";

const initialState = {
  error: null,
  stock: {
    total: 0,
    entities: [],
  },
  loading: false,
  loaded: false,
};

export function stockReducer(state = initialState, action) {
  switch (action.type) {
    case GET_STOCK:
      return { ...state, loading: true, loaded: false };
    case GET_STOCK_SUCCESS:
      return { ...state, stock: action.payload, loading: false, loaded: true };
    case GET_STOCK_ERROR:
      return { ...state, loading: false, loaded: false, error: action.error };
    case CREATE_STOCK_PART:
      return { ...state, loading: true, loaded: false };
    case CREATE_STOCK_PART_SUCCESS:
      return { ...state, loading: false, loaded: true };
    case CREATE_STOCK_PART_ERROR:
      return { ...state, loading: false, loaded: false, error: action.error };
    case UPDATE_STOCK:
      return { ...state, loading: true, loaded: false };
    case UPDATE_STOCK_SUCCESS:
      return { ...state, loading: false, loaded: true };
    case UPDATE_STOCK_ERROR:
      return { ...state, loading: false, loaded: false, error: action.error };
    default:
      return state;
  }
}
