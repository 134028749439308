export const GET_CONTRACTS_DIRECTORY = "GET_CONTRACTS_DIRECTORY";
export const GET_CONTRACTS_DIRECTORY_SUCCESS =
  "GET_CONTRACTS_DIRECTORY_SUCCESS";
export const GET_CONTRACTS_DIRECTORY_ERROR = "GET_CONTRACTS_DIRECTORY_ERROR";

export function getContractsDirectory() {
  return {
    type: GET_CONTRACTS_DIRECTORY,
  };
}

export function getContractsDirectorySuccess(payload) {
  return {
    type: GET_CONTRACTS_DIRECTORY_SUCCESS,
    payload,
  };
}

export function getContractsDirectoryError(error) {
  return {
    type: GET_CONTRACTS_DIRECTORY_ERROR,
    error,
  };
}
