import { all } from "redux-saga/effects";
import watchAuthSaga from "./auth.saga";
import watchUserProfileSaga from "../../pages/Profile/store/saga";
import watchDirectoriesSaga from "./directories/directories.saga";
import watchEmployeesSaga from "../../pages/Work/Employees/store/saga";
import watchTeamsSaga from "../../pages/Work/Teams/store/saga";
import watchMailSaga from "../../pages/Mails/store/saga";
import { watchPartsSaga } from "../../pages/Warehouse/Parts/store/saga";
import { watchSuppliersSaga } from "../../pages/Warehouse/Suppliers/store/saga";
import { watchUsersSaga } from "../../pages/Users/store/saga";
import { watchPartnersSaga } from "../../pages/Job/Partners/store/saga";
import { watchStockSaga } from "../../pages/Warehouse/Stock/store/saga";
import {watchContactsSaga} from "../../pages/Contacts/store/saga";

export default function* rootSaga() {
  yield all([
    watchAuthSaga(),
    watchContactsSaga(),
    watchUserProfileSaga(),
    watchDirectoriesSaga(),
    watchEmployeesSaga(),
    watchTeamsSaga(),
    watchMailSaga(),
    watchPartsSaga(),
    watchSuppliersSaga(),
    watchUsersSaga(),
    watchPartnersSaga(),
    watchStockSaga(),
  ]);
}
