const SESSION = "session";

const getUser = user => {
  return {
    accessToken: user.accessToken,
    tokenType: user.tokenType,
    expiresIn: user.expiresIn,
    username: user.username,
    role: user.role,
    isSignedIn: true
  };
};

export const setCurrentUser = user => {
  localStorage.setItem(SESSION, JSON.stringify(user));
};

export const clearCurrentUser = () => {
  localStorage.removeItem(SESSION);
};

export const getCurrentUser = () => {
  const json = localStorage.getItem(SESSION);
  const user = JSON.parse(json);
  if (user) return getUser(user);
  return {
    accessToken: "",
    tokenType: "",
    expiresIn: "",
    username: "",
    role: "",
    isSignedIn: false
  };
};
