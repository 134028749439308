import {
  CREATE_TEAM,
  CREATE_TEAM_ERROR,
  CREATE_TEAM_SUCCESS,
  GET_TEAMS,
  GET_TEAMS_ERROR,
  GET_TEAMS_SUCCESS,
  UPDATE_TEAM,
  UPDATE_TEAM_ERROR,
  UPDATE_TEAM_SUCCESS
} from "./actions";

const initialState = {
  error: null,
  teams: {
    total: 0,
    entities: []
  },
  loading: false,
  loaded: false
};

export default function teamsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TEAMS:
      return {...state, loading: true, loaded: false};
    case GET_TEAMS_SUCCESS:
      return {...state, teams: action.payload, loading: false, loaded: true};
    case GET_TEAMS_ERROR:
      return {...state, loading: false, loaded: false, error: action.error};
    case CREATE_TEAM:
      return {...state, loading: true, loaded: false};
    case CREATE_TEAM_SUCCESS:
      return {...state, loading: false, loaded: true};
    case CREATE_TEAM_ERROR:
      return {...state, loading: false, loaded: false, error: action.error};
    case UPDATE_TEAM:
      return {...state, loading: true, loaded: false};
    case UPDATE_TEAM_SUCCESS:
      return {...state, loading: false, loaded: true};
    case UPDATE_TEAM_ERROR:
      return {...state, loading: false, loaded: false, error: action.error};
    default:
      return state;
  }
}
