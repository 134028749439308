import {
  GET_TEAMS_DIRECTORY,
  GET_TEAMS_DIRECTORY_ERROR,
  GET_TEAMS_DIRECTORY_SUCCESS
} from "../../actions/directories/teamsDirectory.actions";


const initialState = {
    error: null,
    teams: [],
    loading: false,
    loaded: false
};

export default function teamsDirectoryReducer(state = initialState, action) {
    switch (action.type) {
        case GET_TEAMS_DIRECTORY:
            return {...state, loading: true, loaded: false};
        case GET_TEAMS_DIRECTORY_SUCCESS:
            return {...state, teams: action.payload.entities, loading: false, loaded: true};
        case GET_TEAMS_DIRECTORY_ERROR:
            return {...state, loading: false, loaded: false, error: action.error};
        default:
            return state;
    }
}
