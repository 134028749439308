import {LOGIN_USER, LOGIN_USER_ERROR, LOGIN_USER_SUCCESS, LOGOUT_USER} from "../actions/auth.actions";
import {getCurrentUser, setCurrentUser} from "../../common/helpers/auth.helper";

const initialLoginParameters = {
  error: null,
  user: getCurrentUser(),
  loading: false,
  loaded: false
};

function authReducer(state = initialLoginParameters, action) {
  switch (action.type) {
    case LOGIN_USER:
      return {...state, loading: true, loaded: false};
    case LOGIN_USER_SUCCESS:
      setCurrentUser(action.payload);
      return {...state, user: getCurrentUser(), loading: false, loaded: true};
    case LOGIN_USER_ERROR:
      return {...state, loading: false, loaded: false, error: action.error};
    case LOGOUT_USER:
      return {...state};
    default:
      return state;
  }
}

export default authReducer;
