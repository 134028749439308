import React from "react";
import { Button, Table } from "react-bootstrap";
import PropTypes from "prop-types";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function PartsList({ partsList, showEdit }) {
  return (
    <Table bordered hover>
      <thead>
        <tr>
          <th>Name</th>
          <th>Part Number</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {partsList.map((value, index) => {
          return (
            <tr key={index}>
              <td>{value.name}</td>
              <td>{value.number}</td>
              <td>
                <Button
                  className={"btn btn-accent btn-sm p-2 mr-1"}
                  onClick={() => showEdit(value)}
                >
                  <FontAwesomeIcon icon={faPencilAlt} />
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

PartsList.propTypes = {
  partsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      number: PropTypes.string,
    })
  ).isRequired,
};
