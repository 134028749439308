import {
  GET_COUNTRIES_DIRECTORY,
  GET_COUNTRIES_DIRECTORY_ERROR,
  GET_COUNTRIES_DIRECTORY_SUCCESS
} from "../../actions/directories/countriesDirectory.actions";

const initialState = {
  error: null,
  countries: [],
  loading: false,
  loaded: false
};

export default function countriesDirectoryReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COUNTRIES_DIRECTORY:
      return {...state, loading: true, loaded: false};
    case GET_COUNTRIES_DIRECTORY_SUCCESS:
      return {...state, countries: action.payload.entities, loading: false, loaded: true};
    case GET_COUNTRIES_DIRECTORY_ERROR:
      return {...state, loading: false, loaded: false, error: action.error};
    default:
      return state;
  }
}
