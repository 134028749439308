export const GET_CONTACTS_DIRECTORY = "GET_CONTACTS_DIRECTORY";
export const GET_CONTACTS_DIRECTORY_SUCCESS = "GET_CONTACTS_DIRECTORY_SUCCESS";
export const GET_CONTACTS_DIRECTORY_ERROR = "GET_CONTACTS_DIRECTORY_ERROR";

export function getContactsDirectory() {
    return {
        type: GET_CONTACTS_DIRECTORY
    };
}

export function getContactsDirectorySuccess(payload) {
    return {
        type: GET_CONTACTS_DIRECTORY_SUCCESS,
        payload
    };
}

export function getContactsDirectoryError(error) {
    return {
        type: GET_CONTACTS_DIRECTORY_ERROR,
        error
    };
}
