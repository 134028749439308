import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../../../common/api/api.base";
import {
  CREATE_EMPLOYEE,
  createEmployeeError,
  createEmployeeSuccess,
  GET_EMPLOYEES,
  getEmployees,
  getEmployeesError,
  getEmployeesSuccess,
  UPDATE_EMPLOYEE,
  updateEmployeeError,
  updateEmployeeSuccess,
} from "./actions";

function* workerGetEmployees(action) {
  try {
    const result = yield call(ApiBase.get, `/api/employees`, action.payload);
    if (!result.error) {
      yield put(getEmployeesSuccess(result.value));
    } else {
      yield put(getEmployeesError(`Failed to get GetEmployees!`));
    }
  } catch (e) {
    yield put(getEmployeesError(e.message));
  }
}

function* workerCreateEmployee(action) {
  try {
    const result = yield call(ApiBase.post, `/api/employees`, action.payload);
    if (!result.error) {
      yield put(getEmployees({ offset: 0, take: 10 }));
      yield put(createEmployeeSuccess(result.value));
    } else {
      yield put(createEmployeeError(`Failed to CreateEmployee!`));
    }
  } catch (e) {
    yield put(createEmployeeError(e.message));
  }
}

function* workerUpdateEmployee(action) {
  try {
    const result = yield call(ApiBase.put, `/api/employees`, action.payload);
    if (!result.error) {
      yield put(getEmployees({ offset: 0, take: 10 }));
      yield put(updateEmployeeSuccess(result.value));
    } else {
      yield put(updateEmployeeError(`Failed to UpdateEmployee!`));
    }
  } catch (e) {
    yield put(updateEmployeeError(e.message));
  }
}

export default function* watchEmployeesSaga() {
  yield takeEvery(GET_EMPLOYEES, workerGetEmployees);
  yield takeEvery(CREATE_EMPLOYEE, workerCreateEmployee);
  yield takeEvery(UPDATE_EMPLOYEE, workerUpdateEmployee);
}
