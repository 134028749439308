import React from 'react';
import {Redirect, Route} from "react-router-dom";
import {getCurrentUser} from "../auth.helper";

function PrivateRoute({component: Component, ...rest}) {
  return (
    <Route {...rest} render={props => {
      if (getCurrentUser().isSignedIn) {
        return <Component {...props}/>
      } else {
        return <Redirect to={{
          pathname: "/auth",
          state: {from: props.location}
        }}/>
      }
    }}/>
  );
}

export default PrivateRoute;
