import React, { Suspense } from "react";
import { Switch } from "react-router";
import { Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import FullHeader from "../common/components/blocks/FullLayout/FullHeader";
import FullSidebar from "../common/components/blocks/FullLayout/FullSidebar";
import { logoutUser } from "../redux/actions/auth.actions";
import LoadingSpinner from "../common/components/LoadingSpinner/LoadinSpinner";
import AppRouting from "./App.routing";

const Main = styled.main`
  margin-top: 80px;
`;

function AppContainer({ match }) {
  const dispatch = useDispatch();
  const onLogout = (payload) => dispatch(logoutUser(payload));

  return (
    <>
      <FullHeader onLogout={onLogout} />
      <Container fluid={true}>
        <Row>
          <FullSidebar />
          <Main className="col-md-9 ml-sm-auto col-lg-10 px-4 pl-5">
            <Suspense fallback={<LoadingSpinner />}>
              <Switch>
                <AppRouting match={match} />
              </Switch>
            </Suspense>
          </Main>
        </Row>
      </Container>
    </>
  );
}

export default AppContainer;
