import {
  GET_JOBS_DIRECTORY,
  GET_JOBS_DIRECTORY_ERROR,
  GET_JOBS_DIRECTORY_SUCCESS
} from "../../actions/directories/jobsDirectory.actions";

const initialState = {
  error: null,
  jobs: [],
  loading: false,
  loaded: false
};

export default function jobsDirectoryReducer(state = initialState, action) {
  switch (action.type) {
    case GET_JOBS_DIRECTORY:
      return {...state, loading: true, loaded: false};
    case GET_JOBS_DIRECTORY_SUCCESS:
      return {...state, jobs: action.payload.entities, loading: false, loaded: true};
    case GET_JOBS_DIRECTORY_ERROR:
      return {...state, loading: false, loaded: false, error: action.error};
    default:
      return state;
  }
}
