import React from "react";
import { Route } from "react-router";

const Contracts = React.lazy(() => import("./Contracts/Contracts.container"));
const Projects = React.lazy(() => import("./Projects/Projects.container"));
const Project = React.lazy(() => import("./Project/Project.container"));
const Partners = React.lazy(() => import("./Partners/Partners.container"));

function JobRouting({ match, nestedPath }) {
  return (
    <>
      <Route
        path={`${match.url}/${nestedPath}/contracts`}
        component={Contracts}
      />
      <Route
        path={`${match.url}/${nestedPath}/projects`}
        component={Projects}
      />
      <Route
        path={`${match.url}/${nestedPath}/partners`}
        component={Partners}
      />
      <Route
        path={`${match.url}/${nestedPath}/project/:projectId`}
        component={Project}
      />
    </>
  );
}

export default JobRouting;
