import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../../../common/api/api.base";
import { toast } from "react-toastify";
import {
  CREATE_PART,
  createPartError,
  createPartSuccess,
  GET_PARTS,
  getParts,
  getPartsError,
  getPartsSuccess,
  UPDATE_PART,
  updatePartError,
  updatePartSuccess,
} from "./actions";

function* workerGetParts(action) {
  try {
    const result = yield call(
      ApiBase.get,
      `/api/warehouse/parts`,
      action.payload
    );
    if (!result.error) {
      yield put(getPartsSuccess(result.value));
    } else {
      yield put(getPartsError(`Failed to get contacts!`));
    }
  } catch (e) {
    yield call(toast.error, e.message);
    yield put(getPartsError(e.message));
  }
}

function* workerCreatePart(action) {
  try {
    const result = yield call(
      ApiBase.post,
      `/api/warehouse/parts`,
      action.payload
    );
    if (!result.error) {
      yield put(getParts({ take: 10, offset: 0 }));
      yield put(createPartSuccess(result.value));
    } else {
      yield put(createPartError(`Failed to get CreatePart!`));
    }
  } catch (e) {
    yield put(createPartError(e.message));
  }
}

function* workerUpdatePart(action) {
  try {
    const result = yield call(
      ApiBase.put,
      `/api/warehouse/parts`,
      action.payload
    );
    if (!result.error) {
      yield put(getParts({ take: 10, offset: 0 }));
      yield put(updatePartSuccess(result.value));
    } else {
      yield put(updatePartError(`Failed to get UpdatePart!`));
    }
  } catch (e) {
    yield put(updatePartError(e.message));
  }
}

export function* watchPartsSaga() {
  yield takeEvery(GET_PARTS, workerGetParts);
  yield takeEvery(CREATE_PART, workerCreatePart);
  yield takeEvery(UPDATE_PART, workerUpdatePart);
}
