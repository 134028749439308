import { call, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import {
  LOGIN_USER,
  loginUserError,
  loginUserSuccess,
  LOGOUT_USER,
} from "../actions/auth.actions";
import ApiBase from "../../common/api/api.base";
import { clearCurrentUser } from "../../common/helpers/auth.helper";

function* login(action) {
  try {
    const result = yield call(ApiBase.post, `api/auth/login`, action.payload);
    if (!result.error) {
      yield put(loginUserSuccess(result.value));
      yield put(push("/app/contacts"));
    } else {
      yield put(loginUserError(`Failed to sign in!`));
    }
  } catch (e) {
    // yield put(loginUserError(e.message));
  }
}

function* logout() {
  clearCurrentUser();
  yield put(push("/auth"));
}

export default function* watchAuthSaga() {
  yield takeEvery(LOGIN_USER, login);
  yield takeEvery(LOGOUT_USER, logout);
}
