import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../../common/api/api.base";
import { toast } from "react-toastify";
import {
  GET_PARTNER_INDUSTRIES_DIRECTORY,
  getPartnerIndustriesDirectoryError,
  getPartnerIndustriesDirectorySuccess,
} from "../../actions/directories/partnerIndustriesDirectory.actions";

function* workerGetPartnerIndustriesDirectory() {
  try {
    const result = yield call(
      ApiBase.get,
      `/api/directories/partner-industries`
    );
    if (!result.error) {
      yield put(getPartnerIndustriesDirectorySuccess(result.value));
    } else {
      yield put(
        getPartnerIndustriesDirectoryError(
          `Failed to get GetPartnerIndustriesDirectory!`
        )
      );
    }
  } catch (e) {
    yield put(getPartnerIndustriesDirectoryError(e.message));
  }
}

export default function* watchPartnerIndustriesDirectorySaga() {
  yield takeEvery(
    GET_PARTNER_INDUSTRIES_DIRECTORY,
    workerGetPartnerIndustriesDirectory
  );
}
