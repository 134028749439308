import React from "react";
import { Route } from "react-router";
import { SuppliersContainer } from "./Suppliers/Suppliers.container";
import { PartsContainer } from "./Parts/Parts.container";
import { StockContainer } from "./Stock/Stock.container";

export function WarehouseRouting({ match }) {
  return (
    <>
      <Route path={`${match.url}/parts`} component={PartsContainer} />
      <Route path={`${match.url}/stock`} component={StockContainer} />
      <Route path={`${match.url}/suppliers`} component={SuppliersContainer} />
    </>
  );
}
