import React from "react";
import { Button, Table } from "react-bootstrap";
import PropTypes from "prop-types";
import { faEye, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ContactsList({ contactList, showEdit, showView }) {
  return (
    <Table bordered hover>
      <thead>
        <tr>
          <th>Avatar</th>
          <th>Full Name</th>
          <th>Phone</th>
          <th>Email</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {contactList.map((value, index) => {
          return (
            <tr key={index}>
              <td>
                <img
                  alt={"Avatar"}
                  width={70}
                  height={70}
                  src={value.avatarData}
                  style={{ cursor: "pointer" }}
                  onClick={() => showView(value)}
                />
              </td>
              <td>
                {value.firstName} {value.lastName}
              </td>
              <td>{value.phone}</td>
              <td>{value.email}</td>
              <td>
                <Button
                  className={"btn btn-accent btn-sm p-2 mr-2"}
                  onClick={() => showEdit(value)}
                >
                  <FontAwesomeIcon icon={faPencilAlt} fixedWidth={true} />
                </Button>
                <Button
                  className={"btn btn-accent btn-sm p-2"}
                  onClick={() => showView(value)}
                >
                  <FontAwesomeIcon fixedWidth={true} icon={faEye} />
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

ContactsList.propTypes = {
  contactList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      middleName: PropTypes.string,
      birthDate: PropTypes.instanceOf(Date) | PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
      skype: PropTypes.string,
      address: PropTypes.string,
      zipCode: PropTypes.string,
      contactTypeId: PropTypes.number | null,
      genderId: PropTypes.number | null,
      cityId: PropTypes.number | null,
      avatarData: PropTypes.string | null,
    })
  ).isRequired,
  showEdit: PropTypes.func,
  showView: PropTypes.func,
};

export default ContactsList;
