import {
  GET_PARTNERS_DIRECTORY,
  GET_PARTNERS_DIRECTORY_ERROR,
  GET_PARTNERS_DIRECTORY_SUCCESS
} from "../../actions/directories/partnersDirectory.actions";

const initialState = {
  error: null,
  partners: [],
  loading: false,
  loaded: false
};

export default function partnersDirectoryReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PARTNERS_DIRECTORY:
      return {...state, loading: true, loaded: false};
    case GET_PARTNERS_DIRECTORY_SUCCESS:
      return {...state, partners: action.payload.entities, loading: false, loaded: true};
    case GET_PARTNERS_DIRECTORY_ERROR:
      return {...state, loading: false, loaded: false, error: action.error};
    default:
      return state;
  }
}
