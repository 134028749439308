export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";

// action creators
export function loginUser(payload) {
  return {
    type: LOGIN_USER,
    payload: payload
  };
}

export function loginUserSuccess(payload) {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: payload
  };
}

export function loginUserError(error) {
  return {
    type: LOGIN_USER_ERROR,
    error: error
  };
}

export function logoutUser() {
  return {
    type: LOGOUT_USER
  };
}
