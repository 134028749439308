import React from "react";
import { Button, Table } from "react-bootstrap";
import PropTypes from "prop-types";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function StockList({ stockList, showEdit }) {
  return (
    <Table bordered hover>
      <thead>
        <tr>
          <th>Name</th>
          <th>Part Number</th>
          <th>Supplier</th>
          <th>Position (Row - Shelf)</th>
          <th>Quantity</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {stockList.map((value, index) => {
          return (
            <tr key={index}>
              <td>{value.partName}</td>
              <td>{value.partNumber}</td>
              <td>{value.supplierName}</td>
              <td>
                ({value.row} - {value.shelf})
              </td>
              <td>{value.quantity}</td>
              <td>
                <Button
                  className={"btn btn-accent btn-sm p-2 mr-1"}
                  onClick={() => showEdit(value)}
                >
                  <FontAwesomeIcon icon={faPencilAlt} />
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

StockList.propTypes = {
  stockList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      row: PropTypes.number,
      shelf: PropTypes.number,
      quantity: PropTypes.number,
      partId: PropTypes.number,
      partNumber: PropTypes.string,
      partName: PropTypes.string,
      supplierId: PropTypes.number,
      supplierName: PropTypes.string,
    })
  ).isRequired,
};
