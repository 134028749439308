import {
  CREATE_MAIL,
  CREATE_MAIL_ERROR,
  CREATE_MAIL_SUCCESS,
  GET_MAILS,
  GET_MAILS_ERROR,
  GET_MAILS_SUCCESS
} from "./actions";

const initialState = {
  error: null,
  mails: {
    total: 0,
    entities: []
  },
  loading: false,
  loaded: false
};

export default function mailReducer(state = initialState, action) {
  switch (action.type) {
    case GET_MAILS:
      return { ...state, loading: true, loaded: false };
    case GET_MAILS_SUCCESS:
      return { ...state, mails: action.payload, loading: false, loaded: true };
    case GET_MAILS_ERROR:
      return { ...state, loading: false, loaded: false, error: action.error };
    case CREATE_MAIL:
      return { ...state, loading: true, loaded: false };
    case CREATE_MAIL_SUCCESS:
      return { ...state, loading: false, loaded: true };
    case CREATE_MAIL_ERROR:
      return { ...state, loading: false, loaded: false, error: action.error };
    default:
      return state;
  }
}
