import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../../common/api/api.base";
import { toast } from "react-toastify";
import { GET_PARTNER_INDUSTRIES_DIRECTORY } from "../../actions/directories/partnerIndustriesDirectory.actions";
import {
  getPartnerTypesDirectoryError,
  getPartnerTypesDirectorySuccess,
} from "../../actions/directories/partnerTypesDirectory.actions";

function* workerGetPartnerTypesDirectory() {
  try {
    const result = yield call(ApiBase.get, `/api/directories/partner-types`);
    if (!result.error) {
      yield put(getPartnerTypesDirectorySuccess(result.value));
    } else {
      yield put(
        getPartnerTypesDirectoryError(`Failed to get GetPartnerTypesDirectory!`)
      );
    }
  } catch (e) {
    yield put(getPartnerTypesDirectoryError(e.message));
  }
}

export default function* watchPartnerTypesDirectory() {
  yield takeEvery(
    GET_PARTNER_INDUSTRIES_DIRECTORY,
    workerGetPartnerTypesDirectory
  );
}
