import {
  GET_CONTRACTS_DIRECTORY,
  GET_CONTRACTS_DIRECTORY_ERROR,
  GET_CONTRACTS_DIRECTORY_SUCCESS,
} from "../../actions/directories/contractsDirectory.actions";

const initialState = {
  error: null,
  contracts: [],
  loading: false,
  loaded: false,
};

export default function contractsDirectoryReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_CONTRACTS_DIRECTORY:
      return { ...state, loading: true, loaded: false };
    case GET_CONTRACTS_DIRECTORY_SUCCESS:
      return {
        ...state,
        contracts: action.payload.entities,
        loading: false,
        loaded: true,
      };
    case GET_CONTRACTS_DIRECTORY_ERROR:
      return { ...state, loading: false, loaded: false, error: action.error };
    default:
      return state;
  }
}
