export const GET_PARTNERS_DIRECTORY = "GET_PARTNERS_DIRECTORY";
export const GET_PARTNERS_DIRECTORY_SUCCESS = "GET_PARTNERS_DIRECTORY_SUCCESS";
export const GET_PARTNERS_DIRECTORY_ERROR = "GET_PARTNERS_DIRECTORY_ERROR";

export function getPartnersDirectory() {
    return {
        type: GET_PARTNERS_DIRECTORY

    };
}

export function getPartnersDirectorySuccess(payload) {
    return {
        type: GET_PARTNERS_DIRECTORY_SUCCESS,
        payload
    };
}

export function getPartnersDirectoryError(error) {
    return {
        type: GET_PARTNERS_DIRECTORY_ERROR,
        error
    };
}
