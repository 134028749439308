export const GET_DEPARTMENTS_DIRECTORY = "GET_DEPARTMENTS_DIRECTORY";
export const GET_DEPARTMENTS_DIRECTORY_SUCCESS = "GET_DEPARTMENTS_DIRECTORY_SUCCESS";
export const GET_DEPARTMENTS_DIRECTORY_ERROR = "GET_DEPARTMENTS_DIRECTORY_ERROR";

export function getDepartmentsDirectory() {
    return {
        type: GET_DEPARTMENTS_DIRECTORY
    };
}

export function getDepartmentsDirectorySuccess(payload) {
    return {
        type: GET_DEPARTMENTS_DIRECTORY_SUCCESS,
        payload
    };
}

export function getDepartmentsDirectoryError(error) {
    return {
        type: GET_DEPARTMENTS_DIRECTORY_ERROR,
        error
    };
}
