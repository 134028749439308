export const GET_PARTNER_INDUSTRIES_DIRECTORY = "GET_PARTNER_INDUSTRIES_DIRECTORY";
export const GET_PARTNER_INDUSTRIES_DIRECTORY_SUCCESS = "GET_PARTNER_INDUSTRIES_DIRECTORY_SUCCESS";
export const GET_PARTNER_INDUSTRIES_DIRECTORY_ERROR = "GET_PARTNER_INDUSTRIES_DIRECTORY_ERROR";

export function getPartnerIndustriesDirectory() {
    return {
        type: GET_PARTNER_INDUSTRIES_DIRECTORY
    };
}

export function getPartnerIndustriesDirectorySuccess(payload) {
    return {
        type: GET_PARTNER_INDUSTRIES_DIRECTORY_SUCCESS,
        payload
    };
}

export function getPartnerIndustriesDirectoryError(error) {
    return {
        type: GET_PARTNER_INDUSTRIES_DIRECTORY_ERROR,
        error
    };
}
