import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Contacts from "./Contacts";
import {
  createContact,
  getContact,
  getContacts,
  resetSelectedContact,
  updateContact,
} from "./store/actions";

function ContactsContainer() {
  // Pagination
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const pageSize = 10;

  // Queries
  const contacts = useSelector((state) => state.contacts.contacts);
  const selectedContact = useSelector(
    (state) => state.contacts.selectedContact
  );
  const contactLoading = useSelector((state) => state.contacts.loading);

  // Dispatches
  const dispatch = useDispatch();
  const onGetContacts = (payload) => dispatch(getContacts(payload));
  const onGetContact = (payload) => dispatch(getContact(payload));
  const onUpdateContact = (model) =>
    dispatch(
      updateContact({
        model: model,
        request: { offset: offset, take: pageSize },
      })
    );
  const onCreateContact = (model) =>
    dispatch(
      createContact({
        model: model,
        request: { offset: offset, take: pageSize },
      })
    );
  const onResetContact = () => dispatch(resetSelectedContact());

  useEffect(() => {
    if (selectedContactId) {
      onResetContact();
      onGetContact(selectedContactId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContactId]);

  const offsetChanged = (offset) => {
    setOffset(offset);
    onGetContacts({ offset: offset, take: pageSize });
  };

  return (
    <Contacts
      contacts={contacts}
      selectedContact={selectedContact}
      contactLoading={contactLoading}
      create={onCreateContact}
      update={onUpdateContact}
      pageSize={pageSize}
      offsetChanged={offsetChanged}
      setCurrentPage={setCurrentPage}
      setSelectedContactId={setSelectedContactId}
      currentPage={currentPage}
    />
  );
}

export default ContactsContainer;
