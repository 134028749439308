import React, { useState } from "react";
import SharedPageHeader from "../../../common/components/blocks/SharedPageHeader/SharedPageHeader";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import SharedPagination from "../../../common/components/blocks/SharedPagination/SharedPagination";
import { StockList } from "./components/StockList";
import { StockPartCreateModal } from "./components/StockPartCreateModal";
import { StockPartEditModal } from "./components/StockPartEditModal";

export function Stock({
  stock,
  suppliers,
  pageSize,
  offsetChanged,
  currentPage,
  setCurrentPage,
  create,
  update,
}) {
  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [selectedStockPart, setSelectedStockPart] = useState(null);

  const handleOpenEdit = (stockPart) => {
    setSelectedStockPart(stockPart);
    setShowEdit(true);
  };

  const handleUpdate = (stockPart) => {
    update(stockPart);
    setShowEdit(false);
  };

  const handleCloseEdit = () => setShowEdit(false);
  const handleCloseCreate = () => setShowCreate(false);

  return (
    <>
      <SharedPageHeader
        mainText={"Stock"}
        secondaryText={"List of parts available in the stock"}
      >
        <Button
          variant="accent"
          className="btn-sm mb-2"
          onClick={() => setShowCreate(true)}
        >
          <FontAwesomeIcon fixedWidth={true} icon={faPlus} />
          Add to stock
        </Button>
      </SharedPageHeader>

      <StockList stockList={stock.entities} showEdit={handleOpenEdit} />
      <SharedPagination
        totalRecords={stock.total}
        pageLimit={pageSize}
        pageNeighbours={2}
        setOffset={offsetChanged}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />

      <StockPartEditModal
        show={showEdit}
        suppliers={suppliers}
        selectedStockPart={selectedStockPart}
        handleClose={handleCloseEdit}
        handleUpdate={handleUpdate}
      />

      <StockPartCreateModal
        show={showCreate}
        suppliers={suppliers}
        handleClose={handleCloseCreate}
        handleCreate={create}
      />
    </>
  );
}
