import {
  GET_ADDRESS_TYPES_DIRECTORY,
  GET_ADDRESS_TYPES_DIRECTORY_ERROR,
  GET_ADDRESS_TYPES_DIRECTORY_SUCCESS
} from "../../actions/directories/addressTypesDirectory.actions";

const initialState = {
  error: null,
  addressTypes: [],
  loading: false,
  loaded: false
};

export default function getAddressTypesDirectoryReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ADDRESS_TYPES_DIRECTORY:
      return {...state, loading: true, loaded: false};
    case GET_ADDRESS_TYPES_DIRECTORY_SUCCESS:
      return {...state, addressTypes: action.payload.entities, loading: false, loaded: true};
    case GET_ADDRESS_TYPES_DIRECTORY_ERROR:
      return {...state, loading: false, loaded: false, error: action.error};
    default:
      return state;
  }
}
