import { combineReducers } from "redux";
import authReducer from "./auth.reducer";
import { connectRouter } from "connected-react-router";
import contactsReducer from "../../pages/Contacts/store/reducer";
import userProfileReducer from "../../pages/Profile/store/reducer";
import employeesReducer from "../../pages/Work/Employees/store/reducer";
import teamsReducer from "../../pages/Work/Teams/store/reducer";
import mailsReducer from "../../pages/Mails/store/reducer";
import { suppliersReducer } from "../../pages/Warehouse/Suppliers/store/reducer";
import { partsReducer } from "../../pages/Warehouse/Parts/store/reducer";
import usersReducer from "../../pages/Users/store/reducer";
import directoriesReducer from "./directories/directories.reducer";
import partnersReducer from "../../pages/Job/Partners/store/reducer";
import { stockReducer } from "../../pages/Warehouse/Stock/store/reducer";

const reducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    contacts: contactsReducer,
    userProfile: userProfileReducer,
    employees: employeesReducer,
    teams: teamsReducer,
    mails: mailsReducer,
    users: usersReducer,
    suppliers: suppliersReducer,
    parts: partsReducer,
    directories: directoriesReducer,
    partners: partnersReducer,
    stock: stockReducer,
  });

export default reducer;
