export const GET_STOCK = "GET_STOCK";
export const GET_STOCK_SUCCESS = "GET_STOCK_SUCCESS";
export const GET_STOCK_ERROR = "GET_STOCK_ERROR";

export function getStock(payload) {
  return {
    type: GET_STOCK,
    payload,
  };
}

export function getStockSuccess(payload) {
  return {
    type: GET_STOCK_SUCCESS,
    payload,
  };
}

export function getStockError(error) {
  return {
    type: GET_STOCK_ERROR,
    error,
  };
}

export const CREATE_STOCK_PART = "CREATE_STOCK_PART";
export const CREATE_STOCK_PART_SUCCESS = "CREATE_STOCK_PART_SUCCESS";
export const CREATE_STOCK_PART_ERROR = "CREATE_STOCK_PART_ERROR";

export function createStockPart(payload) {
  return {
    type: CREATE_STOCK_PART,
    payload,
  };
}

export function createStockPartSuccess(payload) {
  return {
    type: CREATE_STOCK_PART_SUCCESS,
    payload,
  };
}

export function createStockPartError(error) {
  return {
    type: CREATE_STOCK_PART_ERROR,
    error,
  };
}

export const UPDATE_STOCK = "UPDATE_STOCK";
export const UPDATE_STOCK_SUCCESS = "UPDATE_STOCK_SUCCESS";
export const UPDATE_STOCK_ERROR = "UPDATE_STOCK_ERROR";

export function updateStock(payload) {
  return {
    type: UPDATE_STOCK,
    payload,
  };
}

export function updateStockSuccess(payload) {
  return {
    type: UPDATE_STOCK_SUCCESS,
    payload,
  };
}

export function updateStockError(error) {
  return {
    type: UPDATE_STOCK_ERROR,
    error,
  };
}
