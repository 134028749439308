import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../../common/api/api.base";
import { toast } from "react-toastify";
import {
  GET_CONTRACTS_DIRECTORY,
  getContractsDirectoryError,
  getContractsDirectorySuccess,
} from "../../actions/directories/contractsDirectory.actions";

function* workerGetContractsDirectory(action) {
  try {
    const result = yield call(
      ApiBase.get,
      `/api/directories/contracts`,
      action.payload
    );
    if (!result.error) {
      yield put(getContractsDirectorySuccess(result.value));
    } else {
      yield put(
        getContractsDirectoryError(`Failed to get GetContractsDirectory!`)
      );
    }
  } catch (e) {
    yield put(getContractsDirectoryError(e.message));
  }
}

export default function* watchContractsDirectorySaga() {
  yield takeEvery(GET_CONTRACTS_DIRECTORY, workerGetContractsDirectory);
}
