export const GET_SUPPLIERS_DIRECTORY = "GET_SUPPLIERS_DIRECTORY";
export const GET_SUPPLIERS_DIRECTORY_SUCCESS =
  "GET_SUPPLIERS_DIRECTORY_SUCCESS";
export const GET_SUPPLIERS_DIRECTORY_ERROR = "GET_SUPPLIERS_DIRECTORY_ERROR";

export function getSuppliersDirectory() {
  return {
    type: GET_SUPPLIERS_DIRECTORY,
  };
}

export function getSuppliersDirectorySuccess(payload) {
  return {
    type: GET_SUPPLIERS_DIRECTORY_SUCCESS,
    payload,
  };
}

export function getSuppliersDirectoryError(error) {
  return {
    type: GET_SUPPLIERS_DIRECTORY_ERROR,
    error,
  };
}
