import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../../../common/api/api.base";
import {
  CREATE_PARTNER,
  createPartnerError,
  createPartnerSuccess,
  GET_PARTNER,
  GET_PARTNERS,
  getPartner,
  getPartnerError,
  getPartners,
  getPartnersError,
  getPartnersSuccess,
  getPartnerSuccess,
  UPDATE_PARTNER,
  updatePartnerError,
  updatePartnerSuccess
} from "./actions";

function* workerGetPartners(action) {
  try {
    const result = yield call(ApiBase.get, `/api/partners`, action.payload);
    if (!result.error) {
      yield put(getPartnersSuccess(result.value));
    } else {
      yield put(getPartnersError(`Failed to get partners!`));
    }
  } catch (e) {
    yield put(getPartnersError(e.message));
  }
}

function* workerGetPartner(action) {
  try {
    const result = yield call(ApiBase.get, `/api/partners/${action.payload}`);
    if (!result.error) {
      yield put(getPartnerSuccess(result.value));
    } else {
      yield put(getPartnerError(`Failed to get partner!`));
    }
  } catch (e) {
    yield put(getPartnerError(e.message));
  }
}

function* workerUpdatePartner(action) {
  try {
    const result = yield call(
      ApiBase.put,
      `/api/partners`,
      action.payload.model
    );
    if (!result.error) {
      yield put(updatePartnerSuccess(result.value));
      yield put(getPartners(action.payload.request));
      yield put(getPartner(action.payload.id));
    } else {
      yield put(updatePartnerError(`Failed to update partner!`));
    }
  } catch (e) {
    yield put(updatePartnerError(e.message));
  }
}

function* workerCreatePartner(action) {
  try {
    const result = yield call(
      ApiBase.post,
      `/api/partners`,
      action.payload.model
    );
    if (!result.error) {
      yield put(createPartnerSuccess(result.value));
      yield put(getPartners(action.payload.request));
    } else {
      yield put(createPartnerError(`Failed to CreatePartner!`));
    }
  } catch (e) {
    yield put(createPartnerError(e.message));
  }
}

export function* watchPartnersSaga() {
  yield takeEvery(GET_PARTNERS, workerGetPartners);
  yield takeEvery(GET_PARTNER, workerGetPartner);
  yield takeEvery(UPDATE_PARTNER, workerUpdatePartner);
  yield takeEvery(CREATE_PARTNER, workerCreatePartner);
}
