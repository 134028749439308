import {
  CREATE_PARTNER,
  CREATE_PARTNER_ERROR,
  CREATE_PARTNER_SUCCESS,
  GET_PARTNER,
  GET_PARTNER_ERROR,
  GET_PARTNER_SUCCESS,
  GET_PARTNERS,
  GET_PARTNERS_ERROR,
  GET_PARTNERS_SUCCESS,
  RESET_SELECTED_PARTNER,
  UPDATE_PARTNER,
  UPDATE_PARTNER_ERROR,
  UPDATE_PARTNER_SUCCESS
} from "./actions";

const initialState = {
  error: null,
  partners: {
    total: 0,
    entities: []
  },
  selectedPartner: {},
  loading: false,
  loaded: false
};

export default function partnersReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PARTNERS:
      return { ...state, loading: true, loaded: false };
    case GET_PARTNERS_SUCCESS:
      return {
        ...state,
        partners: action.payload,
        loading: false,
        loaded: true
      };
    case GET_PARTNERS_ERROR:
      return { ...state, loading: false, loaded: false, error: action.error };

    case GET_PARTNER:
      return { ...state, loading: true, loaded: false };
    case GET_PARTNER_SUCCESS:
      return {
        ...state,
        selectedPartner: action.payload,
        loading: false,
        loaded: true
      };
    case GET_PARTNER_ERROR:
      return { ...state, loading: false, loaded: false, error: action.error };

    case UPDATE_PARTNER:
      return { ...state, loading: true, loaded: false };
    case UPDATE_PARTNER_SUCCESS:
      return { ...state, loading: false, loaded: true };
    case UPDATE_PARTNER_ERROR:
      return { ...state, loading: false, loaded: false, error: action.error };

    case CREATE_PARTNER:
      return { ...state, loading: true, loaded: false };
    case CREATE_PARTNER_SUCCESS:
      return { ...state, loading: false, loaded: true };
    case CREATE_PARTNER_ERROR:
      return { ...state, loading: false, loaded: false, error: action.error };
    case RESET_SELECTED_PARTNER:
      return { ...state, selectedPartner: initialState.selectedPartner };
    default:
      return state;
  }
}
