export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_ERROR = "UPDATE_USER_PROFILE_ERROR";

export function updateUserProfile(payload) {
  return {
    type: UPDATE_USER_PROFILE,
    payload
  };
}

export function updateUserProfileSuccess(payload) {
  return {
    type: UPDATE_USER_PROFILE_SUCCESS,
    payload
  };
}

export function updateUserProfileError(error) {
  return {
    type: UPDATE_USER_PROFILE_ERROR,
    error
  };
}
