import React from "react";
import { Redirect, Route } from "react-router";
import Contacts from "./Contacts/Contacts.container";
import WorkRouting from "./Work/Work.routing";
import JobRouting from "./Job/Job.routing";
import Dashboard from "./Dashboard/Dashboard";
import { WarehouseRouting } from "./Warehouse/Warehouse.routing";

const Profile = React.lazy(() => import("./Profile/Profile.container"));
const Users = React.lazy(() => import("./Users/Users.container"));
const Mail = React.lazy(() => import("./Mails/Mails.container"));

function AppRouting({ match }) {
  return (
    <>
      <WorkRouting match={match} nestedPath={"work"} />
      <JobRouting match={match} nestedPath={"job"} />
      <Route path={`${match.url}/contacts`} component={Contacts} />
      <Route path={`${match.url}/reports`} component={Dashboard} />
      <Route path={`${match.url}/warehouse`} component={WarehouseRouting} />
      <Route path={`${match.url}/profile`} component={Profile} />
      <Route path={`${match.url}/users`} component={Users} />
      <Route path={`${match.url}/mail`} component={Mail} />
      <Redirect to={`${match.url}/contacts`} from={"/"} />
    </>
  );
}

export default AppRouting;
