import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import useForm from "react-hook-form";
import PropTypes from "prop-types";
import HookSelect from "../../../../common/components/wrappers/HookSelect";

export function StockPartCreateModal({
  handleClose,
  handleCreate,
  show,
  suppliers,
}) {
  const { register, handleSubmit, setValue, errors } = useForm();

  const handleSubmitCreate = (values) => {
    handleCreate(values);
    handleClose();
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>New stock item</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(handleSubmitCreate)}>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label htmlFor={"supplierId"}>Supplier</Form.Label>
                  <HookSelect
                    name={"supplierId"}
                    register={register}
                    setValue={setValue}
                    options={suppliers}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label htmlFor={"quantity"}>Quantity</Form.Label>
                  <Form.Control
                    type={"number"}
                    className={errors.quantity ? "is-invalid" : ""}
                    ref={register({ required: true })}
                    name={"quantity"}
                    placeholder={"..."}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label htmlFor={"row"}>Row</Form.Label>
                  <Form.Control
                    type={"number"}
                    className={errors.row ? "is-invalid" : ""}
                    ref={register({ required: true })}
                    name={"row"}
                    placeholder={"..."}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label htmlFor={"shelf"}>Shelf</Form.Label>
                  <Form.Control
                    type={"number"}
                    className={errors.shelf ? "is-invalid" : ""}
                    ref={register({ required: true })}
                    name={"shelf"}
                    placeholder={"..."}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label htmlFor={"partNumber"}>Part Number</Form.Label>
                  <Form.Control
                    type={"text"}
                    className={errors.partNumber ? "is-invalid" : ""}
                    ref={register({ required: true })}
                    name={"partNumber"}
                    placeholder={"..."}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="accent" type="submit">
              Create
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

StockPartCreateModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleCreate: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};
