import { all } from "redux-saga/effects";
import watchAddressTypesDirectorySaga from "./addressTypesDirectory.saga";
import watchContactTypesDirectorySaga from "./contactTypesDirectory.saga";
import watchCountriesDirectorySaga from "./countriesDirectory.saga";
import watchDepartmentsDirectorySaga from "./departmentsDirectory.saga";
import watchPartnerIndustriesDirectorySaga from "./partnerIndustriesDirectory.saga";
import watchPartnerTypesDirectory from "./partnerTypesDirectory.saga";
import watchContactsDirectorySaga from "./contactsDirectory.saga";
import watchJobsDirectorySaga from "./jobsDirectory.saga";
import watchEmployeesDirectorySaga from "./employeesDirectory.saga";
import watchPartnersDirectorySaga from "./partnersDirectory.saga";
import watchContractsDirectorySaga from "./contractsDirectory.saga";
import watchTeamsDirectorySaga from "./teamsDirectory.saga";
import { watchRolesDirectorySaga } from "./rolesDirectory.saga";
import { watchSuppliersDirectorySaga } from "./suppliersDirectory.saga";

export default function* watchDirectoriesSaga() {
  yield all([
    watchAddressTypesDirectorySaga(),
    watchContactTypesDirectorySaga(),
    watchCountriesDirectorySaga(),
    watchDepartmentsDirectorySaga(),
    watchPartnerIndustriesDirectorySaga(),
    watchPartnerTypesDirectory(),
    watchContactsDirectorySaga(),
    watchJobsDirectorySaga(),
    watchEmployeesDirectorySaga(),
    watchPartnersDirectorySaga(),
    watchTeamsDirectorySaga(),
    watchContractsDirectorySaga(),
    watchRolesDirectorySaga(),
    watchSuppliersDirectorySaga(),
  ]);
}
