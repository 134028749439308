import React from "react";
import { Button, Form } from "react-bootstrap";
import useForm from "react-hook-form";

function AuthForm({ onLogin }) {
  const { register, handleSubmit, errors } = useForm();

  const handleLogin = ({ userName, password }) => {
    onLogin({ userName: userName, password: password });
  };

  return (
    <form onSubmit={handleSubmit(handleLogin)}>
      <Form.Group>
        <Form.Label htmlFor={"userName"}>Username</Form.Label>
        <Form.Control
          type={"text"}
          className={errors.userName ? "is-invalid" : ""}
          ref={register({ required: true })}
          name={"userName"}
          placeholder={"Username"}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor={"password"}>Password</Form.Label>
        <Form.Control
          type={"password"}
          className={errors.password ? "is-invalid" : ""}
          ref={register({ required: true })}
          name={"password"}
          placeholder={"Password"}
        />
      </Form.Group>
      <Button type="submit" variant="accent" className="btn-md btn-block">
        Login
      </Button>
    </form>
  );
}

export default AuthForm;
