import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../../common/api/api.base";
import { toast } from "react-toastify";
import {
  GET_CONTACTS_DIRECTORY,
  getContactsDirectoryError,
  getContactsDirectorySuccess,
} from "../../actions/directories/contactsDirectory.actions";

function* workerGetContactsDirectory(action) {
  try {
    const result = yield call(
      ApiBase.get,
      `/api/directories/contacts`,
      action.payload
    );
    if (!result.error) {
      yield put(getContactsDirectorySuccess(result.value));
    } else {
      yield put(
        getContactsDirectoryError(`Failed to get GetContactsDirectory!`)
      );
    }
  } catch (e) {
    yield put(getContactsDirectoryError(e.message));
  }
}

export default function* watchContactsDirectorySaga() {
  yield takeEvery(GET_CONTACTS_DIRECTORY, workerGetContactsDirectory);
}
