import React, { useState } from "react";
import ContactsList from "./components/ContactsList";
import ContactEditModal from "./components/ContactEditModal";
import ContactCreateModal from "./components/ContactCreateModal";
import { Button } from "react-bootstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContactViewModal from "./components/ContactViewModal";
import SharedPagination from "../../common/components/blocks/SharedPagination/SharedPagination";
import SharedPageHeader from "../../common/components/blocks/SharedPageHeader/SharedPageHeader";

export default function Contacts({
  contacts,
  selectedContact,
  pageSize,
  offsetChanged,
  currentPage,
  setCurrentPage,
  create,
  update,
  setSelectedContactId,
  contactLoading,
}) {
  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showView, setShowView] = useState(false);

  const handleOpenView = (contact) => {
    setSelectedContactId(contact.id);
    setShowView(true);
  };

  const handleOpenEdit = (contact) => {
    setSelectedContactId(contact.id);
    setShowEdit(true);
  };

  const handleUpdate = (contact) => {
    update(contact);
    setShowEdit(false);
  };

  const handleCloseEdit = () => setShowEdit(false);
  const handleCloseCreate = () => setShowCreate(false);
  const handleCloseView = () => setShowView(false);

  return (
    <>
      <SharedPageHeader
        mainText={"Contacts"}
        secondaryText={"Our favorite clients contacts"}
      >
        <Button
          variant="accent"
          className="btn-sm mb-2"
          onClick={() => setShowCreate(true)}
        >
          <FontAwesomeIcon fixedWidth={true} icon={faPlus} />
          Add contact
        </Button>
      </SharedPageHeader>

      <ContactsList
        contactList={contacts.entities}
        showEdit={handleOpenEdit}
        showView={handleOpenView}
      />
      <SharedPagination
        totalRecords={contacts.total}
        pageLimit={pageSize}
        pageNeighbours={2}
        setOffset={offsetChanged}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />

      <ContactEditModal
        show={showEdit}
        selectedContact={selectedContact}
        handleClose={handleCloseEdit}
        handleUpdate={handleUpdate}
      />

      <ContactViewModal
        show={showView}
        contactLoading={contactLoading}
        selectedContact={selectedContact}
        handleClose={handleCloseView}
      />

      <ContactCreateModal
        show={showCreate}
        handleClose={handleCloseCreate}
        handleCreate={create}
      />
    </>
  );
}
