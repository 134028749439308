import React from "react";
import { Card } from "react-bootstrap";
import { ResponsivePie } from "@nivo/pie";

function DashboardPieChart(props) {
  return (
    <Card style={{ height: "100%" }}>
      <Card.Body style={{ padding: 0 }}>
        <ResponsivePie
          data={data}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          colors={{ scheme: "nivo" }}
          borderWidth={1}
          borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
          radialLabelsSkipAngle={10}
          radialLabelsTextXOffset={6}
          radialLabelsTextColor="#333333"
          radialLabelsLinkOffset={0}
          radialLabelsLinkDiagonalLength={16}
          radialLabelsLinkHorizontalLength={24}
          radialLabelsLinkStrokeWidth={1}
          radialLabelsLinkColor={{ from: "color" }}
          slicesLabelsSkipAngle={10}
          slicesLabelsTextColor="#333333"
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              size: 4,
              padding: 1,
              stagger: true
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              rotation: -45,
              lineWidth: 6,
              spacing: 10
            }
          ]}
          fill={[
            {
              match: {
                id: "ruby"
              },
              id: "dots"
            },
            {
              match: {
                id: "c"
              },
              id: "dots"
            },
            {
              match: {
                id: "go"
              },
              id: "dots"
            },
            {
              match: {
                id: "python"
              },
              id: "dots"
            },
            {
              match: {
                id: "scala"
              },
              id: "lines"
            },
            {
              match: {
                id: "lisp"
              },
              id: "lines"
            },
            {
              match: {
                id: "elixir"
              },
              id: "lines"
            },
            {
              match: {
                id: "javascript"
              },
              id: "lines"
            }
          ]}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              translateY: 56,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: "#999",
              symbolSize: 18,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000"
                  }
                }
              ]
            }
          ]}
        />
      </Card.Body>
    </Card>
  );
}

export default DashboardPieChart;

const data = [
  {
    id: "hack",
    label: "hack",
    value: 4,
    color: "hsl(257, 70%, 50%)"
  },
  {
    id: "lisp",
    label: "lisp",
    value: 216,
    color: "hsl(330, 70%, 50%)"
  },
  {
    id: "elixir",
    label: "elixir",
    value: 219,
    color: "hsl(65, 70%, 50%)"
  },
  {
    id: "css",
    label: "css",
    value: 94,
    color: "hsl(323, 70%, 50%)"
  },
  {
    id: "go",
    label: "go",
    value: 555,
    color: "hsl(73, 70%, 50%)"
  }
];
