import React from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faUser } from "@fortawesome/free-solid-svg-icons";

const StyledNav = styled(Nav)`
  background-color: #162636 !important;
`;

export default function FullHeader({ onLogout }) {
  return (
    <StyledNav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top shadow">
      <Link className="navbar-brand" to={"/"}>
        Business Brain
      </Link>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <Link className="nav-link" to={"/app/profile"}>
              Profile
              <FontAwesomeIcon className="ml-1" icon={faUser} />
            </Link>
          </li>
          <li className="nav-item">
            <span className="nav-link" onClick={onLogout}>
              Logout
              <FontAwesomeIcon className="ml-1" icon={faSignOutAlt} />
            </span>
          </li>
        </ul>
      </div>
    </StyledNav>
  );
}
