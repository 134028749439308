import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../../../common/api/api.base";
import { toast } from "react-toastify";
import {
  CREATE_STOCK_PART,
  createStockPartError,
  createStockPartSuccess,
  GET_STOCK,
  getStock,
  getStockError,
  getStockSuccess,
  UPDATE_STOCK,
  updateStockError,
  updateStockSuccess,
} from "./actions";

function* workerGetStock(action) {
  try {
    const result = yield call(
      ApiBase.get,
      `/api/warehouse/stock`,
      action.payload
    );
    if (!result.error) {
      yield put(getStockSuccess(result.value));
    } else {
      yield put(getStockError(`Failed to get contacts!`));
    }
  } catch (e) {
    yield call(toast.error, e.message);
    yield put(getStockError(e.message));
  }
}

function* workerCreateStockPart(action) {
  try {
    const result = yield call(
      ApiBase.post,
      `/api/warehouse/stock`,
      action.payload
    );
    if (!result.error) {
      yield put(getStock({ take: 10, offset: 0 }));
      yield put(createStockPartSuccess(result.value));
    } else {
      yield put(createStockPartError(`Failed to get CreateStockPart!`));
    }
  } catch (e) {
    yield put(createStockPartError(e.message));
  }
}

function* workerUpdateStock(action) {
  try {
    const result = yield call(
      ApiBase.put,
      `/api/warehouse/stock`,
      action.payload
    );
    if (!result.error) {
      yield put(getStock({ take: 10, offset: 0 }));
      yield put(updateStockSuccess(result.value));
    } else {
      yield put(updateStockError(`Failed to get UpdateStock!`));
    }
  } catch (e) {
    yield put(updateStockError(e.message));
  }
}

export function* watchStockSaga() {
  yield takeEvery(GET_STOCK, workerGetStock);
  yield takeEvery(CREATE_STOCK_PART, workerCreateStockPart);
  yield takeEvery(UPDATE_STOCK, workerUpdateStock);
}
