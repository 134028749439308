import React, {useState} from 'react';
import {Button} from "react-bootstrap";
import styled from "styled-components";
import PropTypes from "prop-types";
import {truncateString} from "../../helpers/transformers/stringHelper";

const InputGroup = styled.div`
  cursor: pointer;
`;

function SingleFileInput({showFileUpload = true, uploadFile, fileChanged, acceptedFileTypes, additionalClasses}) {
  const [fileForUpload, setFileForUpload] = useState(null);

  const handleUpload = () => uploadFile(fileForUpload);

  const handleFileChange = (e) => {
    const fr = new FileReader();
    const file = e.target.files[0];
    fr.onload = () => {
      setFileForUpload(file);
      if (fileChanged) {
        fileChanged(file);
      }
    };
    fr.readAsDataURL(file);
  };

  return (
    <div className={'input-group ' + additionalClasses}>
      {showFileUpload &&
      <InputGroup className="input-group-prepend">
        <Button className="input-group-text" variant={'accent'} onClick={() => handleUpload()}>Upload</Button>
      </InputGroup>
      }
      <div className="custom-file">
        <input type="file" className="custom-file-input" id="inputGroupFile01"
               onChange={(e) => handleFileChange(e)}
               accept={acceptedFileTypes}
               aria-describedby="inputGroupFileAddon01"/>
        <label className="custom-file-label"
               htmlFor="inputGroupFile01">{(fileForUpload && truncateString(fileForUpload.name)) || 'Choose file'}</label>
      </div>
    </div>
  );
}

SingleFileInput.propTypes = {
  uploadFile: PropTypes.func,
  showFileUpload: PropTypes.bool,
  fileChanged: PropTypes.func,
  acceptedFileTypes: PropTypes.string
};

export default SingleFileInput;
