import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { persistReducer, persistStore } from "redux-persist";
import rootReducer from "../reducers/root.reducer";
import rootSaga from "../sagas/root.saga";
import { routerMiddleware } from "connected-react-router";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
// import { loadUser } from "redux-oidc";
// import userManager from "../api/auth";

const persistConfig = { key: "root", storage };

export const history = createBrowserHistory();

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

export default function (initialState = {}) {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  const store = createStore(
    persistedReducer,
    initialState,
    compose(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(rootSaga);

  // loadUser(store, userManager);

  let persistor = persistStore(store);

  return { store, persistor };
}
