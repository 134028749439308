import { call, put, takeEvery } from "redux-saga/effects";
import {
  CREATE_TEAM,
  createTeamError,
  createTeamSuccess,
  GET_TEAMS,
  getTeams,
  getTeamsError,
  getTeamsSuccess,
  UPDATE_TEAM,
  updateTeamError,
  updateTeamSuccess,
} from "./actions";
import ApiBase from "../../../../common/api/api.base";

function* workerGetTeams(action) {
  try {
    const result = yield call(ApiBase.get, `/api/teams`, action.payload);
    if (!result.error) {
      yield put(getTeamsSuccess(result.value));
    } else {
      yield put(getTeamsError(`Failed to get GetTeams!`));
    }
  } catch (e) {
    yield put(getTeamsError(e.message));
  }
}

function* workerCreateTeam(action) {
  try {
    const result = yield call(ApiBase.post, `/api/teams`, action.payload);
    if (!result.error) {
      yield put(getTeams({ offset: 0, pageSize: 10 }));
      yield put(createTeamSuccess(result.value));
    } else {
      yield put(createTeamError(`Failed to get CreateTeam!`));
    }
  } catch (e) {
    yield put(createTeamError(e.message));
  }
}

function* workerUpdateTeam(action) {
  try {
    const result = yield call(ApiBase.put, `/api/teams`, action.payload);
    if (!result.error) {
      yield put(getTeams({ offset: 0, pageSize: 10 }));
      yield put(updateTeamSuccess(result.value));
    } else {
      yield put(updateTeamError(`Failed to get UpdateTeam!`));
    }
  } catch (e) {
    yield put(updateTeamError(e.message));
  }
}

export default function* watchTeamsSaga() {
  yield takeEvery(GET_TEAMS, workerGetTeams);
  yield takeEvery(CREATE_TEAM, workerCreateTeam);
  yield takeEvery(UPDATE_TEAM, workerUpdateTeam);
}
