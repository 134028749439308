export const GET_TEAMS = "GET_TEAMS";
export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS";
export const GET_TEAMS_ERROR = "GET_TEAMS_ERROR";

export function getTeams(payload) {
  return {
    type: GET_TEAMS,
    payload
  };
}

export function getTeamsSuccess(payload) {
  return {
    type: GET_TEAMS_SUCCESS,
    payload
  };
}

export function getTeamsError(error) {
  return {
    type: GET_TEAMS_ERROR,
    error
  };
}

export const CREATE_TEAM = "CREATE_TEAM";
export const CREATE_TEAM_SUCCESS = "CREATE_TEAM_SUCCESS";
export const CREATE_TEAM_ERROR = "CREATE_TEAM_ERROR";

export function createTeam(payload) {
  return {
    type: CREATE_TEAM,
    payload
  };
}

export function createTeamSuccess(payload) {
  return {
    type: CREATE_TEAM_SUCCESS,
    payload
  };
}

export function createTeamError(error) {
  return {
    type: CREATE_TEAM_ERROR,
    error
  };
}

export const UPDATE_TEAM = "UPDATE_TEAM";
export const UPDATE_TEAM_SUCCESS = "UPDATE_TEAM_SUCCESS";
export const UPDATE_TEAM_ERROR = "UPDATE_TEAM_ERROR";

export function updateTeam(payload) {
  return {
    type: UPDATE_TEAM,
    payload
  };
}

export function updateTeamSuccess(payload) {
  return {
    type: UPDATE_TEAM_SUCCESS,
    payload
  };
}

export function updateTeamError(error) {
  return {
    type: UPDATE_TEAM_ERROR,
    error
  };
}
