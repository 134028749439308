import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../../common/api/api.base";
import { toast } from "react-toastify";
import {
  GET_PARTNERS_DIRECTORY,
  getPartnersDirectoryError,
  getPartnersDirectorySuccess,
} from "../../actions/directories/partnersDirectory.actions";

function* workerGetPartners(action) {
  try {
    const result = yield call(
      ApiBase.get,
      `/api/directories/partners`,
      action.payload
    );
    if (!result.error) {
      yield put(getPartnersDirectorySuccess(result.value));
    } else {
      yield put(getPartnersDirectoryError(`Failed to get GetPartners!`));
    }
  } catch (e) {
    yield put(getPartnersDirectoryError(e.message));
  }
}

export default function* watchPartnersDirectorySaga() {
  yield takeEvery(GET_PARTNERS_DIRECTORY, workerGetPartners);
}
