import {
  GET_PARTNER_TYPES_DIRECTORY,
  GET_PARTNER_TYPES_DIRECTORY_ERROR,
  GET_PARTNER_TYPES_DIRECTORY_SUCCESS
} from "../../actions/directories/partnerTypesDirectory.actions";

const initialState = {
  error: null,
  partnerTypes: [],
  loading: false,
  loaded: false
};

export default function partnerTypesDirectoryReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PARTNER_TYPES_DIRECTORY:
      return {...state, loading: true, loaded: false};
    case GET_PARTNER_TYPES_DIRECTORY_SUCCESS:
      return {...state, partnerTypes: action.payload.entities, loading: false, loaded: true};
    case GET_PARTNER_TYPES_DIRECTORY_ERROR:
      return {...state, loading: false, loaded: false, error: action.error};
    default:
      return state;
  }
}
