export const GET_CONTACTS = `CONTACTS/GET_CONTACTS`;
export const GET_CONTACTS_SUCCESS = "CONTACTS/GET_CONTACTS_SUCCESS";
export const GET_CONTACTS_ERROR = "CONTACTS/GET_CONTACTS_ERROR";

export function getContacts(payload) {
  return {
    type: GET_CONTACTS,
    payload: payload
  };
}

export function getContactsSuccess(payload) {
  return {
    type: GET_CONTACTS_SUCCESS,
    payload: payload
  };
}

export function getContactsError(error) {
  return {
    type: GET_CONTACTS_ERROR,
    error: error
  };
}

export const GET_CONTACT = "CONTACTS/GET_CONTACT";
export const GET_CONTACT_SUCCESS = "CONTACTS/GET_CONTACT_SUCCESS";
export const GET_CONTACT_ERROR = "CONTACTS/GET_CONTACT_ERROR";

export function getContact(payload) {
  return {
    type: GET_CONTACT,
    payload: payload
  };
}

export function getContactSuccess(payload) {
  return {
    type: GET_CONTACT_SUCCESS,
    payload: payload
  };
}

export function getContactError(error) {
  return {
    type: GET_CONTACT_ERROR,
    error: error
  };
}

export const UPDATE_CONTACT = "CONTACTS/UPDATE_CONTACT";
export const UPDATE_CONTACT_SUCCESS = "CONTACTS/UPDATE_CONTACT_SUCCESS";
export const UPDATE_CONTACT_ERROR = "CONTACTS/UPDATE_CONTACT_ERROR";

export function updateContact(payload) {
  return {
    type: UPDATE_CONTACT,
    payload
  };
}

export function updateContactSuccess(payload) {
  return {
    type: UPDATE_CONTACT_SUCCESS,
    payload
  };
}

export function updateContactError(error) {
  return {
    type: UPDATE_CONTACT_ERROR,
    error
  };
}

export const CREATE_CONTACT = "CONTACTS/CREATE_CONTACT";
export const CREATE_CONTACT_SUCCESS = "CONTACTS/CREATE_CONTACT_SUCCESS";
export const CREATE_CONTACT_ERROR = "CONTACTS/CREATE_CONTACT_ERROR";

export function createContact(payload) {
  return {
    type: CREATE_CONTACT,
    payload
  };
}

export function createContactSuccess(payload) {
  return {
    type: CREATE_CONTACT_SUCCESS,
    payload
  };
}

export function createContactError(error) {
  return {
    type: CREATE_CONTACT_ERROR,
    error
  };
}

export const RESET_SELECTED_CONTACT = "CONTACTS/RESET_SELECTED_CONTACT";

export function resetSelectedContact() {
  return {
    type: RESET_SELECTED_CONTACT
  };
}
