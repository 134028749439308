export const GET_TEAMS_DIRECTORY = "GET_TEAMS_DIRECTORY";
export const GET_TEAMS_DIRECTORY_SUCCESS = "GET_TEAMS_DIRECTORY_SUCCESS";
export const GET_TEAMS_DIRECTORY_ERROR = "GET_TEAMS_DIRECTORY_ERROR";

export function getTeamsDirectory() {
    return {
        type: GET_TEAMS_DIRECTORY,

    };
}

export function getTeamsDirectorySuccess(payload) {
    return {
        type: GET_TEAMS_DIRECTORY_SUCCESS,
        payload
    };
}

export function getTeamsDirectoryError(error) {
    return {
        type: GET_TEAMS_DIRECTORY_ERROR,
        error
    };
}
