export const GET_COUNTRIES_DIRECTORY = "GET_COUNTRIES_DIRECTORY";
export const GET_COUNTRIES_DIRECTORY_SUCCESS = "GET_COUNTRIES_DIRECTORY_SUCCESS";
export const GET_COUNTRIES_DIRECTORY_ERROR = "GET_COUNTRIES_DIRECTORY_ERROR";

export function getCountriesDirectory() {
    return {
        type: GET_COUNTRIES_DIRECTORY
    };
}

export function getCountriesDirectorySuccess(payload) {
    return {
        type: GET_COUNTRIES_DIRECTORY_SUCCESS,
        payload
    };
}

export function getCountriesDirectoryError(error) {
    return {
        type: GET_COUNTRIES_DIRECTORY_ERROR,
        error
    };
}
