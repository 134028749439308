import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import styled from "styled-components";
import { Spinner } from "react-bootstrap";

const Wrapper = styled.div`
  height: 100%;
`;

export default function LoadingSpinner(props) {
  return (
    <Wrapper className="container-fluid h-100">
      <Row className="row h-100">
        <Col />
        <Col className="flex-column align-middle d-flex justify-content-center">
          <Card>
            <Card.Body>
              <Spinner animation="border" />
              Loading...
            </Card.Body>
          </Card>
        </Col>
        <Col />
      </Row>
    </Wrapper>
  );
}
