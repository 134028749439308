import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../../../common/api/api.base";
import { toast } from "react-toastify";
import {
  CREATE_SUPPLIER,
  createSupplierError,
  createSupplierSuccess,
  GET_SUPPLIERS,
  getSuppliers,
  getSuppliersError,
  getSuppliersSuccess,
  UPDATE_SUPPLIER,
  updateSupplierError,
  updateSupplierSuccess,
} from "./actions";

function* workerGetSuppliers(action) {
  try {
    const result = yield call(
      ApiBase.get,
      `/api/warehouse/suppliers`,
      action.payload
    );
    if (!result.error) {
      yield put(getSuppliersSuccess(result.value));
    } else {
      yield put(getSuppliersError(`Failed to get contacts!`));
    }
  } catch (e) {
    yield call(toast.error, e.message);
    yield put(getSuppliersError(e.message));
  }
}

function* workerCreateSupplier(action) {
  try {
    const result = yield call(
      ApiBase.post,
      `/api/warehouse/suppliers`,
      action.payload
    );
    if (!result.error) {
      yield put(getSuppliers({ take: 10, offset: 0 }));
      yield put(createSupplierSuccess(result.value));
    } else {
      yield put(createSupplierError(`Failed to get CreateSupplier!`));
    }
  } catch (e) {
    yield put(createSupplierError(e.message));
  }
}

function* workerUpdateSupplier(action) {
  try {
    const result = yield call(
      ApiBase.put,
      `/api/warehouse/suppliers`,
      action.payload
    );
    if (!result.error) {
      yield put(getSuppliers({ take: 10, offset: 0 }));
      yield put(updateSupplierSuccess(result.value));
    } else {
      yield put(updateSupplierError(`Failed to get UpdateSupplier!`));
    }
  } catch (e) {
    yield put(updateSupplierError(e.message));
  }
}

export function* watchSuppliersSaga() {
  yield takeEvery(GET_SUPPLIERS, workerGetSuppliers);
  yield takeEvery(UPDATE_SUPPLIER, workerUpdateSupplier);
  yield takeEvery(CREATE_SUPPLIER, workerCreateSupplier);
}
