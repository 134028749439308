import React from "react";
import { Col, Row } from "react-bootstrap";
import DashboardBarChart from "./DashboardBarChart";
import DashboardPieChart from "./DashboardPieChart";

function DashboardHeader(props) {
  return (
    <Row>
      <Col lg={7} md={7}>
        <DashboardBarChart />
      </Col>
      <Col lg={5} md={5}>
        <DashboardPieChart />
      </Col>
    </Row>
  );
}

export default DashboardHeader;
