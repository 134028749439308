import React, { useState } from "react";
import SharedPageHeader from "../../../common/components/blocks/SharedPageHeader/SharedPageHeader";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import SharedPagination from "../../../common/components/blocks/SharedPagination/SharedPagination";
import { PartCreateModal } from "./components/PartCreateModal";
import { PartEditModal } from "./components/PartEditModal";
import { PartsList } from "./components/PartsList";

export function Parts({
  parts,
  pageSize,
  offsetChanged,
  currentPage,
  setCurrentPage,
  create,
  update,
}) {
  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [selectedPart, setSelectedPart] = useState(null);

  const handleOpenEdit = (part) => {
    setSelectedPart(part);
    setShowEdit(true);
  };

  const handleUpdate = (part) => {
    update(part);
    setShowEdit(false);
  };

  const handleCloseEdit = () => setShowEdit(false);
  const handleCloseCreate = () => setShowCreate(false);

  return (
    <>
      <SharedPageHeader
        mainText={"Parts"}
        secondaryText={"List of existing parts in the system"}
      >
        <Button
          variant="accent"
          className="btn-sm mb-2"
          onClick={() => setShowCreate(true)}
        >
          <FontAwesomeIcon fixedWidth={true} icon={faPlus} />
          Add part
        </Button>
      </SharedPageHeader>

      <PartsList partsList={parts.entities} showEdit={handleOpenEdit} />
      <SharedPagination
        totalRecords={parts.total}
        pageLimit={pageSize}
        pageNeighbours={2}
        setOffset={offsetChanged}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />

      <PartEditModal
        show={showEdit}
        selectedPart={selectedPart}
        handleClose={handleCloseEdit}
        handleUpdate={handleUpdate}
      />

      <PartCreateModal
        show={showCreate}
        handleClose={handleCloseCreate}
        handleCreate={create}
      />
    </>
  );
}
