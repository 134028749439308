import {format, parseISO} from "date-fns";

export function parseDateToString(date, dateFormat = "MM/dd/yyyy") {
  if (date) {
    const parsedDate = date instanceof Date ? date : parseISO(date);
    return format(parsedDate, dateFormat);
  } else {
    return null;
  }
}

export function parseStringToDate(date) {
  if (date) {
    return date instanceof Date ? date : parseISO(date);
  } else {
    return null;
  }
}

