export const GET_PARTNERS = "GET_PARTNERS";
export const GET_PARTNERS_SUCCESS = "GET_PARTNERS_SUCCESS";
export const GET_PARTNERS_ERROR = "GET_PARTNERS_ERROR";

export function getPartners(payload) {
  return {
    type: GET_PARTNERS,
    payload
  };
}

export function getPartnersSuccess(payload) {
  return {
    type: GET_PARTNERS_SUCCESS,
    payload
  };
}

export function getPartnersError(error) {
  return {
    type: GET_PARTNERS_ERROR,
    error
  };
}

export const GET_PARTNER = "GET_PARTNER";
export const GET_PARTNER_SUCCESS = "GET_PARTNER_SUCCESS";
export const GET_PARTNER_ERROR = "GET_PARTNER_ERROR";

export function getPartner(payload) {
  return {
    type: GET_PARTNER,
    payload
  };
}

export function getPartnerSuccess(payload) {
  return {
    type: GET_PARTNER_SUCCESS,
    payload
  };
}

export function getPartnerError(error) {
  return {
    type: GET_PARTNER_ERROR,
    error
  };
}

export const UPDATE_PARTNER = "UPDATE_PARTNER";
export const UPDATE_PARTNER_SUCCESS = "UPDATE_PARTNER_SUCCESS";
export const UPDATE_PARTNER_ERROR = "UPDATE_PARTNER_ERROR";

export function updatePartner(payload) {
  return {
    type: UPDATE_PARTNER,
    payload
  };
}

export function updatePartnerSuccess(payload) {
  return {
    type: UPDATE_PARTNER_SUCCESS,
    payload
  };
}

export function updatePartnerError(error) {
  return {
    type: UPDATE_PARTNER_ERROR,
    error
  };
}

export const CREATE_PARTNER = "CREATE_PARTNER";
export const CREATE_PARTNER_SUCCESS = "CREATE_PARTNER_SUCCESS";
export const CREATE_PARTNER_ERROR = "CREATE_PARTNER_ERROR";

export function createPartner(payload) {
  return {
    type: CREATE_PARTNER,
    payload
  };
}

export function createPartnerSuccess(payload) {
  return {
    type: CREATE_PARTNER_SUCCESS,
    payload
  };
}

export function createPartnerError(error) {
  return {
    type: CREATE_PARTNER_ERROR,
    error
  };
}

export const RESET_SELECTED_PARTNER = "RESET_SELECTED_PARTNER";

export function resetSelectedPartner() {
  return {
    type: RESET_SELECTED_PARTNER
  };
}
