import React from 'react';
import ScreenCenterLayout from "../../common/components/blocks/ScreenCenterLayout/ScreenCenterLayout";

function NotFound(props) {
  return (
    <ScreenCenterLayout
      cardColor={"danger"}
      cardTextColor={"white"}
      cardBodyTitle={"Page not found!"}
      cardBodyContent={"This page has been removed or never existed. Try another page..."}
    />
  );
}

export default NotFound;
