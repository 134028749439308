import React from "react";
import { Button, Col, Image, Modal, Row, Spinner } from "react-bootstrap";
import ViewLabelValue from "../../../common/components/blocks/ViewLabelValue/ViewLabelValue";
import PropTypes from "prop-types";
import { SimpleSpinner } from "../../../common/components/blocks/SimpleSpinner/SimpleSpinner";
import { parseDateToString } from "../../../common/helpers/transformers/datesHelper";

function ContactViewModal({
  handleClose,
  show,
  selectedContact,
  contactLoading,
}) {
  return (
    <>
      <Modal size={"lg"} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>View Contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {contactLoading ? (
            <SimpleSpinner />
          ) : (
            <Row>
              <Col md={6} lg={5} className={"text-center"}>
                <Image
                  className="fluid"
                  height={250}
                  width={250}
                  src={selectedContact.avatarData}
                />
              </Col>
              <Col>
                <h4>
                  {selectedContact.firstName} {selectedContact.middleName}{" "}
                  {selectedContact.lastName}
                </h4>
                <p>{selectedContact.email}</p>
                <ViewLabelValue label={"Phone"} value={selectedContact.phone} />
                <ViewLabelValue
                  label={"Birth Date"}
                  value={parseDateToString(selectedContact.birthDate)}
                />
                <ViewLabelValue label={"Skype"} value={selectedContact.skype} />
                <ViewLabelValue
                  label={"Address"}
                  value={selectedContact.address}
                />
                <ViewLabelValue
                  label={"Zip Code"}
                  value={selectedContact.zipCode}
                />
                <ViewLabelValue
                  label={"Contact Type"}
                  value={selectedContact.contactTypeName}
                />
                <ViewLabelValue
                  label={"Gender"}
                  value={selectedContact.genderName}
                />
                <ViewLabelValue
                  label={"City"}
                  value={selectedContact.cityName}
                />
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

ContactViewModal.propTypes = {
  selectedContact: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    middleName: PropTypes.string,
    birthDate: PropTypes.instanceOf(Date) | PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    skype: PropTypes.string,
    address: PropTypes.string,
    zipCode: PropTypes.string,
    contactTypeId: PropTypes.number | null,
    contactTypeName: PropTypes.string | null,
    genderId: PropTypes.number | null,
    genderName: PropTypes.string | null,
    cityName: PropTypes.string | null,
    avatarData: PropTypes.string | null,
  }),
  handleClose: PropTypes.func,
  show: PropTypes.bool,
};

export default ContactViewModal;
