import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import useForm from "react-hook-form";
import PropTypes from "prop-types";

export function SupplierCreateModal({ handleClose, handleCreate, show }) {
  const { register, handleSubmit, setValue, errors } = useForm();

  const handleSubmitCreate = (values) => {
    handleCreate(values);
    handleClose();
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>New Supplier</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(handleSubmitCreate)}>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label htmlFor={"name"}>Name</Form.Label>
                  <Form.Control
                    type={"text"}
                    className={errors.name ? "is-invalid" : ""}
                    ref={register({ required: true })}
                    name={"name"}
                    placeholder={"..."}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label htmlFor={"companyCode"}>Company Code</Form.Label>
                  <Form.Control
                    type={"text"}
                    className={errors.companyCode ? "is-invalid" : ""}
                    ref={register({ required: true })}
                    name={"companyCode"}
                    placeholder={"..."}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label htmlFor={"vat"}>VAT</Form.Label>
                  <Form.Control
                    type={"text"}
                    className={errors.vat ? "is-invalid" : ""}
                    ref={register({ required: true })}
                    name={"vat"}
                    placeholder={"..."}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label htmlFor={"phone"}>Phone</Form.Label>
                  <Form.Control
                    type={"text"}
                    className={errors.phone ? "is-invalid" : ""}
                    ref={register({ required: true })}
                    name={"phone"}
                    placeholder={"..."}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label htmlFor={"address"}>Address</Form.Label>
                  <Form.Control
                    type={"text"}
                    className={errors.address ? "is-invalid" : ""}
                    ref={register({ required: true })}
                    name={"address"}
                    placeholder={"..."}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="accent" type="submit">
              Create
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

SupplierCreateModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleCreate: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  supplier: PropTypes.object,
};
