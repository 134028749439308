import React from "react";

function ViewLabelValue({
  label,
  value,
  additionalClasses = "",
  hideIfEmpty = true,
}) {
  return (
    <>
      {hideIfEmpty && value && (
        <p className={"py-0 mb-1" + additionalClasses}>
          <strong className={"mr-1"}>{label}:</strong> {value}
        </p>
      )}
    </>
  );
}

export default ViewLabelValue;
