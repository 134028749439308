import configureStore from "./config/configureStore";

const configuredStore = configureStore({});
export const {persistor} = configuredStore;
export const {store} = configuredStore;
store.subscribe(() => {
  // const { auth } = store.getState();
  // localStorage.setItem("session", auth);
});

export const {dispatch} = store;
