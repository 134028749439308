import React from 'react';
import PropTypes from "prop-types";

function SharedPageHeader({mainText, secondaryText, children}) {
  return (
    <div className={'d-flex justify-content-between'}>
      <div>
        <h2>
          {mainText}
        </h2>
        {secondaryText &&
        <p className="text-muted">
          {secondaryText}
        </p>
        }
      </div>
      {children &&
      <div className={'align-self-end'}>
        {children}
      </div>
      }
    </div>
  );
}

SharedPageHeader.propTypes = {
  mainText: PropTypes.string,
  secondaryText: PropTypes.string
};

export default SharedPageHeader;
